// eslint-disable-next-line @typescript-eslint/no-unused-vars
const hide = "hide";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const show = "show";
const replacePart = "replacePart";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const applyMaterial = "applyMaterial";

const bindPart = "bindPart";
const unbind = "unbind";

const hideAccessories = [
  {
    type: "hide",
    node: [
      "nameboard11_300",
      "nameboard21_300",
      "nameboard31_300",
      "nameboard41_300",
      "nameboard51_300",
      "nameboard61_300",
      "nameboard81_300",
      "nameboard101_300",
      "nameboard22_300",
      "nameboard42_300",
      "nameboard62_300",
      "nameboard82_300",
      "nameboard102_300",
      "nameboard11_350",
      "nameboard21_350",
      "nameboard31_350",
      "nameboard41_350",
      "nameboard51_350",
      "nameboard61_350",
      "nameboard81_350",
      "nameboard101_350",
      "nameboard22_350",
      "nameboard42_350",
      "nameboard62_350",
      "nameboard82_350",
      "nameboard102_350",
    ],
  },
  {
    type: "hide",
    node: [
      "penholder11_300",
      "penholder21_300",
      "penholder31_300",
      "penholder41_300",
      "penholder51_300",
      "penholder61_300",
      "penholder81_300",
      "penholder101_300",
      "penholder22_300",
      "penholder42_300",
      "penholder62_300",
      "penholder82_300",
      "penholder102_300",
      "penholder11_350",
      "penholder21_350",
      "penholder31_350",
      "penholder41_350",
      "penholder51_350",
      "penholder61_350",
      "penholder81_350",
      "penholder101_350",
      "penholder22_350",
      "penholder42_350",
      "penholder62_350",
      "penholder82_350",
      "penholder102_350",
    ],
  },
  {
    type: "hide",
    node: [
      "tray11_300",
      "tray21_300",
      "tray31_300",
      "tray41_300",
      "tray51_300",
      "tray61_300",
      "tray81_300",
      "tray101_300",
      "tray22_300",
      "tray42_300",
      "tray62_300",
      "tray82_300",
      "tray102_300",
      "tray11_350",
      "tray21_350",
      "tray31_350",
      "tray41_350",
      "tray51_350",
      "tray61_350",
      "tray81_350",
      "tray101_350",
      "tray22_350",
      "tray42_350",
      "tray62_350",
      "tray82_350",
      "tray102_350",
    ],
  },
];

const hideModestiesRules = [
  {
    type: "hide",
    node: ["modesty_wood_left", "modesty_perforated_left"],
  },
  {
    type: "hide",
    node: ["modesty_wood_right", "modesty_perforated_right"],
  },
];

const wireAccessPositionGromet = [
  {
    name: "Gromet Position",
    required: false,
    multiselect: false,
    dropdown: true,
    options: [
      {
        name: "Left",
        thumbnail: "/assets/components/GrometLeft.png",
        value: "Left",
      },
      {
        name: "Center",
        thumbnail: "/assets/components/GrometCenter.png",

        value: "Center",
      },
      {
        name: "Right",
        thumbnail: "/assets/components/GrometRight.png",
        value: "Right",
      },
    ],
  },
];
const wireAccessPositionFlipUp = [
  {
    name: "FlipUp Position",
    required: false,
    multiselect: false,
    dropdown: true,
    options: [
      {
        name: "Left",
        thumbnail: "/assets/components/FlipUpLeft.png",
        value: "Left",
      },
      {
        name: "Center",
        thumbnail: "/assets/components/FlipUpCenter.png",
        value: "Center",
      },
      {
        name: "Right",
        thumbnail: "/assets/components/FlipUpRight.png",
        value: "Right",
      },
    ],
  },
];

const returnScreenNone = [
  {
    name: "None",
    thumbnail: "/assets/components/none.jpg",
    pid: "",
    rules: [
      {
        type: "hide",
        node: [
          "return_screen_",
          "return_screen_1",
          "return_screen_2",
          "return_screen_3",
          "return_screen_4",
        ],
      },
      {
        type: "hide",
        node: [
          "return_screen_001",
          "return_screen_003",
          "return_screen_005",
          "return_screen_007",
          "return_screen_009",
          "return_screen_011",
          "return_screen_013",
        ],
      },
      {
        type: "hide",
        node: [
          "return_screen_",
          "return_screen_002",
          "return_screen_004",
          "return_screen_006",
          "return_screen_008",
          "return_screen_010",
          "return_screen_012",
        ],
      },
    ],
  },
];

const returnScreen18mm = [
  {
    name: "Fabric-18MM With Mag Strip-300H",
    thumbnail: "/assets/components/18mm300.jpg",
    rules: [
      {
        type: "show",
        node: [
          "return_screen_",
          "return_screen_1",
          "return_screen_2",
          "return_screen_3",
          "return_screen_4",
        ],
      },
      {
        type: replacePart,
        node: [
          "return_screen_001",
          "return_screen_003",
          "return_screen_005",
          "return_screen_007",
          "return_screen_009",
          "return_screen_011",
          "return_screen_013",
        ],
        pid: "616419082f9790553aa01dc2", //612f5ade96a01418a6d9916b   614d70424373e0184a2057df
      },
      {
        type: replacePart,
        node: [
          "return_screen_",
          "return_screen_002",
          "return_screen_004",
          "return_screen_006",
          "return_screen_008",
          "return_screen_010",
          "return_screen_012",
        ],
        pid: "6163c22c736bc9439f182b7c", //612f9ab52f48ef21a3eb7552  614d604a7962a24fa22d4904
      },
    ],
  },
  {
    name: "Fabric-18MM With Mag Strip-350H",
    thumbnail: "/assets/components/18mm350.jpg",
    rules: [
      {
        type: "show",
        node: [
          "return_screen_",
          "return_screen_1",
          "return_screen_2",
          "return_screen_3",
          "return_screen_4",
        ],
      },
      {
        type: replacePart,
        node: [
          "return_screen_001",
          "return_screen_003",
          "return_screen_005",
          "return_screen_007",
          "return_screen_009",
          "return_screen_011",
          "return_screen_013",
        ],
        pid: "6163f88cc67f5e949f385102", //6135f14c945b46c299920127  614d536c1bff12842df67206
      },
      {
        type: replacePart,
        node: [
          "return_screen_",
          "return_screen_002",
          "return_screen_004",
          "return_screen_006",
          "return_screen_008",
          "return_screen_010",
          "return_screen_012",
        ],
        pid: "6163f0d1f67d61afa82ab51a", //6135f24f945b46c29992012a   614d4c4667cd1d38aa3ba4f3
      },
    ],
  },
];

const returnScreenGlass = [
  {
    name: "Glass-8MM-300H",
    thumbnail: "/assets/components/8mm300.jpg",
    rules: [
      {
        type: "show",
        node: [
          "return_screen_",
          "return_screen_1",
          "return_screen_2",
          "return_screen_3",
          "return_screen_4",
        ],
      },
      {
        type: hide,
        node: ["Fabric_Left", "Fabric_Right"],
      },
      {
        type: replacePart,
        node: [
          "return_screen_001",
          "return_screen_003",
          "return_screen_005",
          "return_screen_007",
          "return_screen_009",
          "return_screen_011",
          "return_screen_013",
        ],
        pid: "612f55dc96a01418a6d99144",
      },
      {
        type: replacePart,
        node: [
          "return_screen_",
          "return_screen_002",
          "return_screen_004",
          "return_screen_006",
          "return_screen_008",
          "return_screen_010",
          "return_screen_012",
        ],
        pid: "612f9e174ffc5e9ec1cf499d",
      },
    ],
  },
  {
    name: "Glass-8MM-350H",
    thumbnail: "/assets/components/8mm350.jpg",
    rules: [
      {
        type: "show",
        node: [
          "return_screen_",
          "return_screen_1",
          "return_screen_2",
          "return_screen_3",
          "return_screen_4",
        ],
      },
      {
        type: replacePart,
        node: [
          "return_screen_001",
          "return_screen_003",
          "return_screen_005",
          "return_screen_007",
          "return_screen_009",
          "return_screen_011",
          "return_screen_013",
        ],
        pid: "6135f55052eec497f127c5cd",
      },
      {
        type: replacePart,
        node: [
          "return_screen_",
          "return_screen_002",
          "return_screen_004",
          "return_screen_006",
          "return_screen_008",
          "return_screen_010",
          "return_screen_012",
        ],
        pid: "6135f7ac3219427d3b8da556",
      },
    ],
  },
];
const returnScrees30mm = [
  {
    name: "30MM AL Screen Edge-Edge-300H",
    thumbnail: "/assets/components/30mmal300ete.jpg",
    rules: [
      {
        type: "show",
        node: [
          "return_screen_",
          "return_screen_1",
          "return_screen_2",
          "return_screen_3",
          "return_screen_4",
        ],
      },
      {
        type: replacePart,
        node: [
          "return_screen_001",
          "return_screen_003",
          "return_screen_005",
          "return_screen_007",
          "return_screen_009",
          "return_screen_011",
          "return_screen_013",
        ],
        pid: "61497b8681e422c191ff0ab8", //6135e0d5b2dba4c8b32aa0cb
      },
      {
        type: replacePart,
        node: [
          "return_screen_",
          "return_screen_002",
          "return_screen_004",
          "return_screen_006",
          "return_screen_008",
          "return_screen_010",
          "return_screen_012",
        ],
        pid: "6149760e5fb436b30a789625", //6135e963fb4295804fa4945f 61497b8681e422c191ff0ab8
      },
    ],
  },
  {
    name: "30MM AL Screen Edge-Edge-350H",
    thumbnail: "/assets/components/30mmal350ete.jpg",
    rules: [
      {
        type: "show",
        node: [
          "return_screen_",
          "return_screen_1",
          "return_screen_2",
          "return_screen_3",
          "return_screen_4",
        ],
      },
      {
        type: replacePart,
        node: [
          "return_screen_001",
          "return_screen_003",
          "return_screen_005",
          "return_screen_007",
          "return_screen_009",
          "return_screen_011",
          "return_screen_013",
        ],
        pid: "6149840fa0471879eee32079", //6135eef0945b46c299920123 6149840fa0471879eee32079
      },
      {
        type: replacePart,
        node: [
          "return_screen_",
          "return_screen_002",
          "return_screen_004",
          "return_screen_006",
          "return_screen_008",
          "return_screen_010",
          "return_screen_012",
        ],
        pid: "61498192b29ed986c59e9701", //6135f042945b46c299920125
      },
    ],
  },
  {
    name: "30 MM AL Screen 300H",
    thumbnail: "/assets/components/30mmal300.jpg",
    rules: [
      {
        type: "show",
        node: [
          "return_screen_",
          "return_screen_1",
          "return_screen_2",
          "return_screen_3",
          "return_screen_4",
        ],
      },
      {
        type: replacePart,
        node: [
          "return_screen_001",
          "return_screen_003",
          "return_screen_005",
          "return_screen_007",
          "return_screen_009",
          "return_screen_011",
          "return_screen_013",
        ],
        pid: "623d65e7b1b2e306403d5fd2", //6135e0d5b2dba4c8b32aa0cb
      },
      {
        type: replacePart,
        node: [
          "return_screen_",
          "return_screen_002",
          "return_screen_004",
          "return_screen_006",
          "return_screen_008",
          "return_screen_010",
          "return_screen_012",
        ],
        pid: "623d6b1deda928d8842eea2f", //6135e963fb4295804fa4945f 61497b8681e422c191ff0ab8
      },
    ],
  },
  {
    name: "30 MM AL Screen 350H",
    thumbnail: "/assets/components/30mmal350.jpg",
    rules: [
      {
        type: "show",
        node: [
          "return_screen_",
          "return_screen_1",
          "return_screen_2",
          "return_screen_3",
          "return_screen_4",
        ],
      },
      {
        type: replacePart,
        node: [
          "return_screen_001",
          "return_screen_003",
          "return_screen_005",
          "return_screen_007",
          "return_screen_009",
          "return_screen_011",
          "return_screen_013",
        ],
        pid: "623d6c1aeda928d8842eea36",
      },
      {
        type: replacePart,
        node: [
          "return_screen_",
          "return_screen_002",
          "return_screen_004",
          "return_screen_006",
          "return_screen_008",
          "return_screen_010",
          "return_screen_012",
        ],
        pid: "623d7057261fe1c8d1c3fa1e",
      },
    ],
  },
];

const raceway200shared = [
  {
    name: "Wire Access",
    required: false,

    options: [
      {
        name: "D-cut",
        thumbnail: "/assets/components/dcut.png",
        disableFeatures: ["Gromet Position", "FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6165795fc2008e8588d23e3c",
          },
        ],
      },
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6164008a2b645ed54da63ca2",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6164008a2b645ed54da63ca2",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ac233e2d2c659fa942a8",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ac574d7fa2d23341f169",
          },
        ],
      },

      {
        name: "Gromet",
        thumbnail: "/assets/components/gromet.jpeg",
        enableFeatures: ["Gromet Position"],
        disableFeatures: ["FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "627ca84a4665d4f68e3cecb3", //613634a73e7765281446915c  616578f6c2008e8588d23e30
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "627ca84a4665d4f68e3cecb3", //613634a73e7765281446915c
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ae663e2d2c659fa94353",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1aeb11c04edd8dbbf3c10",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4, 6, 8, 10, 12] }],
            node: [
              "intermediate_leg",
              "intermediate_leg2s",
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "6284ea1de87e7ae551004bd4",
          },

          // {
          //   type: replacePart,
          //   node: "intermediate_leg",
          //   pid: "611a12cd6a3c46c1c3ed0fe3",
          // },
        ],
      },
      {
        name: "Vertical Wire Entry",
        thumbnail: "/assets/components/verticalwireentrybig.png",
        rules: [
          { type: "hide", node: "intermediate_leg" },
          {
            type: replacePart,
            node: [
              "intermediate_leg",
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "612cd97818ef64345b011715",
          },
          {
            type: replacePart,
            node: ["intermediate_leg2s"],
            pid: "6242f235d8350df1d029e97b",
          },
          {
            type: replacePart,
            node: "flipdowntray1_shared",
            pid: "612b6d13debf3b0db2e468a8",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [2, 6, 8, 10, 12] }],
            node: [
              "flipdowntray1_shared_5",
              "flipdowntray1_shared_4",
              "flipdowntray1_shared_3",
              "flipdowntray1_shared_2",
              "flipdowntray1_shared_1",
            ],
            pid: "6243fd2f9af82cea8e022543",
          },
          // {
          //   type: replacePart,
          //   node: "intermediate_modesty_shared_1",
          //   pid: "612b2fc1abfcc0ebe5ea1c82",
          // },
        ],
      },
    ],
  },
];
const raceway200sharedlaminate = [
  {
    name: "Wire Access",
    required: false,

    options: [
      {
        name: "D-cut",
        thumbnail: "/assets/components/dcut.png",
        disableFeatures: ["FlipUp Position", "Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6165795fc2008e8588d23e3c",
          },
        ],
      },
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6164008a2b645ed54da63ca2",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6164008a2b645ed54da63ca2",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ac233e2d2c659fa942a8",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ac574d7fa2d23341f169",
          },
        ],
      },

      {
        name: "Gromet",
        thumbnail: "/assets/components/gromet.jpeg",
        enableFeatures: ["Gromet Position"],
        disableFeatures: ["FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "627ca84a4665d4f68e3cecb3", //613634a73e7765281446915c
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "627ca84a4665d4f68e3cecb3", //613634a73e7765281446915c
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ae663e2d2c659fa94353",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1aeb11c04edd8dbbf3c10",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4, 6, 8, 10, 12] }],
            node: [
              "intermediate_leg",
              "intermediate_leg2s",
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "6284ea1de87e7ae551004bd4", //611a12cd6a3c46c1c3ed0fe3
          }
        ],
      },
      {
        name: "Vertical Wire Entry",
        thumbnail: "/assets/components/verticalwireentrybig.png",
        rules: [
          { type: "hide", node: "intermediate_leg" },
          {
            type: replacePart,
            node: [
              "intermediate_leg",
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "612cd97818ef64345b011715",
          },
          {
            type: replacePart,
            node: ["intermediate_leg2s"],
            pid: "6242f235d8350df1d029e97b",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [2, 6, 8, 10, 12] }],
            node: "flipdowntray1_shared",
            pid: "627c9f8fdaa3841ca3bb0bfd",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [2, 6, 8, 10, 12] }],
            node: [
              "flipdowntray1_shared_5",
              "flipdowntray1_shared_4",
              "flipdowntray1_shared_3",
              "flipdowntray1_shared_2",
              "flipdowntray1_shared_1",
            ],
            pid: "627cadff085d02604b991d98",
          },
        ],
      },
    ],
  },
];

const raceway200nonshared = [
  {
    name: "Wire Access",
    nodeName: "table_top_nonshared",
    required: false,

    options: [
      {
        name: "D-cut",
        thumbnail: "/assets/components/dcut.png",
        disableFeatures: ["Gromet Position", "FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657d57b762ed0a66b814af",
          },
        ],
      },
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657b67b762ed0a66b8148f",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657b67b762ed0a66b8148f",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ad091c04edd8dbbf3b58",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1adad4d7fa2d23341f1c4",
          },
        ],
      },

      {
        name: "Gromet",
        thumbnail: "/assets/components/gromet.jpeg",
        enableFeatures: ["Gromet Position"],
        disableFeatures: ["FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "627cb37a5bf42286e68945b8",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "627cb37a5bf42286e68945b8",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ade944b80448fcf7e297",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ae1b3e2d2c659fa9433d",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    // nodeName: "intermediate_modesty_nonshared",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [1, 2, 3] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",

            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [5] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [6] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg6ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
        ],
      },
      {
        name: "Vertical Wire Entry",
        thumbnail: "/assets/components/verticalwireentrybig.png",
        rules: [
          { type: "hide", node: "intermediate_leg" },
          {
            type: replacePart,
            node: "cable_tray",
            pid: "612cb4761219fe195da960bd",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4, 5, 6] }],
            node: [
              "cable_tray_1",
              "cable_tray_2",
              "cable_tray_3",
              "cable_tray_4",
              "cable_tray_5",
            ],
            pid: "6243ff4ab705734af8c942b7",
          },
          {
            type: replacePart,
            node: [
              "intermediate_leg",
              "intermediate_leg2ns",
              "intermediate_leg3ns",
              "intermediate_leg3Ans",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
              "intermediate_leg6ns",
            ],
            pid: "612d35f55b9d36d5804618de",
          },
          {
            type: replacePart,
            node: ["intermediate_leg1ns"],
            pid: "6242e845026209b748ffcdf1",
          },
        ],
      },
    ],
  },
];
const raceway200nonsharedlaminate = [
  {
    name: "Wire Access",
    nodeName: "table_top_nonshared",
    required: false,

    options: [
      {
        name: "D-cut",
        thumbnail: "/assets/components/dcut.png",
        disableFeatures: ["Gromet Position", "FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657d57b762ed0a66b814af",
          },
        ],
      },
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657b67b762ed0a66b8148f",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657b67b762ed0a66b8148f",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ad091c04edd8dbbf3b58",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1adad4d7fa2d23341f1c4",
          },
        ],
      },

      {
        name: "Gromet",
        thumbnail: "/assets/components/gromet.jpeg",
        enableFeatures: ["Gromet Position"],
        disableFeatures: ["FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "627cb37a5bf42286e68945b8",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "627cb37a5bf42286e68945b8",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ade944b80448fcf7e297",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ae1b3e2d2c659fa9433d",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionGromet,
  ...wireAccessPositionFlipUp,
  {
    name: "Wire Entry",
    // nodeName: "intermediate_modesty_nonshared",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [1, 2, 3] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",

            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [5] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [6] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg6ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
        ],
      },
      {
        name: "Vertical Wire Entry",
        thumbnail: "/assets/components/verticalwireentrybig.png",
        rules: [
          { type: "hide", node: "intermediate_leg" },
          {
            type: replacePart,
            node: "cable_tray",
            pid: "627cc081ce05d993b92baed9",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4, 5, 6] }],
            node: [
              "cable_tray_1",
              "cable_tray_2",
              "cable_tray_3",
              "cable_tray_4",
              "cable_tray_5",
            ],
            pid: "627cc213ce05d993b92baedf",
          },
          {
            type: replacePart,
            node: [
              "intermediate_leg",
              "intermediate_leg2ns",
              "intermediate_leg3ns",
              "intermediate_leg3Ans",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
              "intermediate_leg6ns",
            ],
            pid: "612d35f55b9d36d5804618de",
          },
          {
            type: replacePart,
            node: ["intermediate_leg1ns"],
            pid: "6242e845026209b748ffcdf1",
          },
        ],
      },
    ],
  },
];
const raceway400shared = [
  {
    name: "Wire Access",
    nodeName: "table_top_shared",
    required: false,
    options: [
      {
        name: "D-cut",
        thumbnail: "/assets/components/dcut.png",
        disableFeatures: ["Gromet Position", "FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6165795fc2008e8588d23e3c",
          },
        ],
      },
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6164008a2b645ed54da63ca2",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6164008a2b645ed54da63ca2",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ac233e2d2c659fa942a8",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ac574d7fa2d23341f169",
          },
        ],
      },

      {
        name: "Gromet",
        thumbnail: "/assets/components/gromet.jpeg",
        enableFeatures: ["Gromet Position"],
        disableFeatures: ["FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "627ca84a4665d4f68e3cecb3", //613634a73e7765281446915c
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "627ca84a4665d4f68e3cecb3",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ae663e2d2c659fa94353",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1aeb11c04edd8dbbf3c10",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4, 6, 8, 10, 12] }],

            node: [
              "intermediate_leg",
              "intermediate_leg2s",
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "6284ea1de87e7ae551004bd4",
          }
        ],
      },
      {
        name: "Vertical Wire Entry",
        thumbnail: "/assets/components/verticalwireentrysmall.png",
        rules: [
          { type: "hide", node: "intermediate_leg" },
          {
            type: replacePart,
            node: [
              "intermediate_leg",
              //"intermediate_leg2s",
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "612cd97818ef64345b011715",
          },
          {
            type: replacePart,
            node: [
              //have to set it up with a smaller product
              "intermediate_leg2s",
            ],
            pid: "6242f235d8350df1d029e97b",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [2, 6, 8, 10, 12] }],
            node: "flipdowntray1_shared",
            pid: "627cf44f823e9b29fd70e153",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [2, 6, 8, 10, 12] }],
            node: [
              "flipdowntray1_shared_5",
              "flipdowntray1_shared_4",
              "flipdowntray1_shared_3",
              "flipdowntray1_shared_2",
              "flipdowntray1_shared_1",
            ],
            pid: "627cf509823e9b29fd70e158",
          },
        ],
      },
    ],
  },
];
const raceway400sharedlaminate = [
  {
    name: "Wire Access",
    nodeName: "table_top_shared",
    required: false,
    options: [
      {
        name: "D-cut",
        thumbnail: "/assets/components/dcut.png",
        disableFeatures: ["Gromet Position", "FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6165795fc2008e8588d23e3c", //6114b42ac8901af0221ede67
          },
        ],
      },
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6164008a2b645ed54da63ca2",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6164008a2b645ed54da63ca2",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ac233e2d2c659fa942a8",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ac574d7fa2d23341f169",
          },
        ],
      },

      {
        name: "Gromet",
        thumbnail: "/assets/components/gromet.jpeg",
        enableFeatures: ["Gromet Position"],
        disableFeatures: ["FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "627ca84a4665d4f68e3cecb3", //613634a73e7765281446915c
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "627ca84a4665d4f68e3cecb3",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ae663e2d2c659fa94353",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1aeb11c04edd8dbbf3c10",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4, 6, 8, 10, 12] }],
            node: [
              "intermediate_leg",
              "intermediate_leg2s",
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "6284ea1de87e7ae551004bd4",
          }
        ],
      },
      {
        name: "Vertical Wire Entry",
        thumbnail: "/assets/components/verticalwireentrysmall.png",
        rules: [
          { type: "hide", node: "intermediate_leg" },
          {
            type: replacePart,
            node: [
              "intermediate_leg",
              //"intermediate_leg2s",
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "612cd97818ef64345b011715",
          },
          {
            type: replacePart,
            node: [
              //have to set it up with a smaller product
              "intermediate_leg2s",
            ],
            pid: "6242f235d8350df1d029e97b",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [2, 6, 8, 10, 12] }],
            node: "flipdowntray1_shared",
            pid: "627cdfe4fae46bb49752dc57",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [2, 6, 8, 10, 12] }],
            node: [
              "flipdowntray1_shared_5",
              "flipdowntray1_shared_4",
              "flipdowntray1_shared_3",
              "flipdowntray1_shared_2",
              "flipdowntray1_shared_1",
            ],
            pid: "627ceeca3dbdb5ea2a525609",
          },
        ],
      },
    ],
  },
];

const raceway400nonshared = [
  {
    name: "Wire Access",
    nodeName: "table_top_nonshared",
    required: false,
    options: [
      {
        name: "D-cut",
        thumbnail: "/assets/components/dcut.png",
        disableFeatures: ["Gromet Position", "FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657d57b762ed0a66b814af", //611a0f4408dc8e016c4e8f15
          },
        ],
      },
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657b67b762ed0a66b8148f",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657b67b762ed0a66b8148f",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ad091c04edd8dbbf3b58",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1adad4d7fa2d23341f1c4",
          },
        ],
      },

      {
        name: "Gromet",
        thumbnail: "/assets/components/gromet.jpeg",
        enableFeatures: ["Gromet Position"],
        disableFeatures: ["FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "627cb37a5bf42286e68945b8",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "627cb37a5bf42286e68945b8",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ade944b80448fcf7e297",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ae1b3e2d2c659fa9433d",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    // nodeName: "intermediate_modesty_nonshared",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [1, 2, 3] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",

            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [5] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [6] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg6ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
        ],
      },
      {
        name: "Vertical Wire Entry",
        thumbnail: "/assets/components/verticalwireentrysmall.png",
        nodeName: "intermediate_modesty_nonshared",
        rules: [
          { type: "hide", node: "intermediate_leg" },

          {
            type: replacePart,
            node: "cable_tray",
            pid: "627de6a7e56be348895a8888",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4, 5, 6] }],
            node: [
              "cable_tray_1",
              "cable_tray_2",
              "cable_tray_3",
              "cable_tray_4",
              "cable_tray_5",
            ],
            pid: "627de738e56be348895a888c",
          },
          {
            type: replacePart,
            node: [
              "intermediate_leg",
              "intermediate_leg2ns",
              "intermediate_leg3ns",
              "intermediate_leg3Ans",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
              "intermediate_leg6ns",
            ],
            pid: "612d35f55b9d36d5804618de",
          },
          {
            type: replacePart,
            node: ["intermediate_leg1ns"],
            pid: "6242e845026209b748ffcdf1",
          },
        ],
      },
    ],
  },
];
const raceway400nonsharedlaminate = [
  {
    name: "Wire Access",
    nodeName: "table_top_nonshared",
    required: false,
    options: [
      {
        name: "D-cut",
        thumbnail: "/assets/components/dcut.png",
        disableFeatures: ["Gromet Position", "FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657d57b762ed0a66b814af", //611a0f4408dc8e016c4e8f15
          },
        ],
      },
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657b67b762ed0a66b8148f",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657b67b762ed0a66b8148f",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ad091c04edd8dbbf3b58",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1adad4d7fa2d23341f1c4",
          },
        ],
      },

      {
        name: "Gromet",
        thumbnail: "/assets/components/gromet.jpeg",
        enableFeatures: ["Gromet Position"],
        disableFeatures: ["FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "627cb37a5bf42286e68945b8",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "627cb37a5bf42286e68945b8",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ade944b80448fcf7e297",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ae1b3e2d2c659fa9433d",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    // nodeName: "intermediate_modesty_nonshared",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [1, 2, 3] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",

            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [5] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [6] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg6ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
        ],
      },
      {
        name: "Vertical Wire Entry",
        thumbnail: "/assets/components/verticalwireentrysmall.png",
        nodeName: "intermediate_modesty_nonshared",
        rules: [
          { type: "hide", node: "intermediate_leg" },

          {
            type: replacePart,
            node: "cable_tray",
            pid: "627de48d674925b0c47d42a8",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4, 5, 6] }],
            node: [
              "cable_tray_1",
              "cable_tray_2",
              "cable_tray_3",
              "cable_tray_4",
              "cable_tray_5",
            ],
            pid: "627de616e56be348895a8884",
          },
          {
            type: replacePart,
            node: [
              "intermediate_leg",
              "intermediate_leg2ns",
              "intermediate_leg3ns",
              "intermediate_leg3Ans",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
              "intermediate_leg6ns",
            ],
            pid: "612d35f55b9d36d5804618de",
          },
          {
            type: replacePart,
            node: ["intermediate_leg1ns"],
            pid: "6242e845026209b748ffcdf1",
          },
        ],
      },
    ],
  },
];
const flipdowntray_shared = [
  {
    name: "Wire Access",
    required: false,
    options: [
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62d11b9889192ceb1a9e3da3",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62d11b9889192ceb1a9e3da3",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62bac6ceb2578b9292e15cc8",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62bac691b2578b9292e15cc4",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    //nodeName: "intermediate_modesty_shared",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [2, 4, 6, 8, 10, 12] }],
            node: [
              "intermediate_leg",
              "intermediate_leg2s",
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "611a12cd6a3c46c1c3ed0fe3",
          },
        ],
      },
    ],
  },
];

const flipdowntray_nonshared = [
  {
    name: "Wire Access",
    required: false,
    options: [
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62d11afe485ca124a7614c88",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62d11afe485ca124a7614c88",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62bac632b2578b9292e15cc0",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62bac5c6b2578b9292e15cbd",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [1, 2, 3] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",

            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [5] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [6] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg6ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
        ],
      },
    ],
  },
];

const boxraceway = [
  {
    name: "Wire Access",
    required: false,

    options: [
      {
        name: "D-cut",
        thumbnail: "/assets/components/dcut.png",
        disableFeatures: ["Gromet Position", "FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6165795fc2008e8588d23e3c", //6114b42ac8901af0221ede67
          },
        ],
      },
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6164008a2b645ed54da63ca2",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "6164008a2b645ed54da63ca2",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ac233e2d2c659fa942a8",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ac574d7fa2d23341f169",
          },
        ],
      },

      {
        name: "Gromet",
        thumbnail: "/assets/components/gromet.jpeg",
        enableFeatures: ["Gromet Position"],
        disableFeatures: ["FlipUp Position"],
        rules: [
          {
            type: replacePart,

            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "627ca84a4665d4f68e3cecb3", //613634a73e7765281446915c
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Center"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "627ca84a4665d4f68e3cecb3",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Left"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1ae663e2d2c659fa94353",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Right"] }],
            node: [
              "table_top_shared",
              "table_top_shared_1",
              "table_top_shared_2",
              "table_top_shared_3",
              "table_top_shared_4",
              "table_top_shared_5",
            ],
            pid: "62a1aeb11c04edd8dbbf3c10",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4, 6, 8, 10, 12] }],
            node: [
              "intermediate_leg",
              "intermediate_leg2s",
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "6284ea1de87e7ae551004bd4",
          },

          // {
          //   type: replacePart,
          //   node: "intermediate_leg",
          //   pid: "611a12cd6a3c46c1c3ed0fe3",
          // },
        ],
      },
      {
        name: "Vertical Wire Entry",
        thumbnail: "/assets/components/verticalwireentrybig.png",
        rules: [
          { type: "hide", node: "intermediate_leg" },
          {
            type: replacePart,
            node: [
              "intermediate_leg4s",
              "intermediate_leg6s",
              "intermediate_leg8s",
              "intermediate_leg10s",
              "intermediate_leg12s",
            ],
            pid: "612cd97818ef64345b011715",
          },
          {
            type: replacePart,
            node: ["intermediate_leg2s"],
            pid: "6242f235d8350df1d029e97b",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [2, 6, 8, 10, 12] }],
            node: "flipdowntray1_shared",
            pid: "612e864cfb9cbd036918bc59",
          },
          //Vertical wire entry switch
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [2, 6, 8, 10, 12] }],
            node: [
              "flipdowntray1_shared_5",
              "flipdowntray1_shared_4",
              "flipdowntray1_shared_3",
              "flipdowntray1_shared_2",
              "flipdowntray1_shared_1",
            ],
            pid: "6243f46712e39e2e28c9aa3a",
          },
          // {
          //   type: replacePart,
          //   node: "intermediate_modesty_shared_1",
          //   pid: "612b2fc1abfcc0ebe5ea1c82",
          // },
        ],
      },
    ],
  },
];
const boxracewaynonshared = [
  {
    name: "Wire Access",

    required: false,
    options: [
      {
        name: "D-cut",
        thumbnail: "/assets/components/dcut.png",
        disableFeatures: ["FlipUp Position", "Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657d57b762ed0a66b814af", //6114b42ac8901af0221ede67
          },
          // {
          //   type: replacePart,
          //   node: "table_top_shared_1",
          //   pid: "6114b42ac8901af0221ede67",
          // },
        ],
      },
      {
        name: "FlipUp",
        thumbnail: "/assets/components/flipup.png",
        enableFeatures: ["FlipUp Position"],
        disableFeatures: ["Gromet Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657b67b762ed0a66b8148f",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "61657b67b762ed0a66b8148f",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ad091c04edd8dbbf3b58",
          },
          {
            type: replacePart,
            condition: [{ feature: "FlipUp Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1adad4d7fa2d23341f1c4",
          },
        ],
      },

      {
        name: "Gromet",
        thumbnail: "/assets/components/gromet.jpeg",
        enableFeatures: ["Gromet Position"],
        disableFeatures: ["FlipUp Position"],
        rules: [
          {
            type: replacePart,
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "627cb37a5bf42286e68945b8",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Center"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "627cb37a5bf42286e68945b8",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Left"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ade944b80448fcf7e297",
          },
          {
            type: replacePart,
            condition: [{ feature: "Gromet Position", values: ["Right"] }],
            node: [
              "table_top_nonshared",
              "table_top_nonshared_1",
              "table_top_nonshared_2",
              "table_top_nonshared_3",
              "table_top_nonshared_4",
              "table_top_nonshared_5",
            ],
            pid: "62a1ae1b3e2d2c659fa9433d",
          },
        ],
      },
    ],
  },
  ...wireAccessPositionFlipUp,
  ...wireAccessPositionGromet,
  {
    name: "Wire Entry",
    required: false,
    options: [
      {
        name: "Intermediate-Leg Wire Entry",
        thumbnail: "/assets/components/intermediatewireentry.png",
        rules: [
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [1, 2, 3] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",

            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [5] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [6] }],
            node: [
              "intermediate_leg",
              "intermediate_leg1ns",
              "intermediate_leg2ns",
              "intermediate_leg6ns",
            ],
            pid: "6119e2ac2984eb83f8c74cff",
          },
          // {
          //   type: replacePart,
          //   node: "intermediate_leg",
          //   pid: "611a12cd6a3c46c1c3ed0fe3",
          // },
        ],
      },
      {
        name: "Vertical Wire Entry",
        thumbnail: "/assets/components/verticalwireentrybig.png",
        rules: [
          { type: "hide", node: "intermediate_leg" },

          {
            type: replacePart,
            node: [
              "intermediate_leg",
              "intermediate_leg2ns",
              "intermediate_leg3ns",
              "intermediate_leg3Ans",
              "intermediate_leg4Ans",
              "intermediate_leg4ns",
              "intermediate_leg5ns",
              "intermediate_leg5Ans",
              "intermediate_leg6ns",
            ],
            pid: "612d35f55b9d36d5804618de",
          },
          {
            type: replacePart,
            node: ["intermediate_leg1ns"],
            pid: "6242e845026209b748ffcdf1",
          },
          {
            type: replacePart,
            node: "cable_tray",
            pid: "612e8f5b5fa66c79ee4b0c6e",
          },
          {
            type: replacePart,
            condition: [{ feature: "noOfSeats", values: [4, 5, 6] }],
            node: [
              "cable_tray_1",
              "cable_tray_2",
              "cable_tray_3",
              "cable_tray_4",
              "cable_tray_5",
            ],
            pid: "624408739dc7ff36bc227587",
          },
          // {
          //   type: replacePart,
          //   node: "intermediate_modesty_shared_1",
          //   pid: "612b2fc1abfcc0ebe5ea1c82",
          // },
        ],
      },
    ],
  },
];
export const ConfData3 = Object.freeze([
  {
    name: "System",
    multiSelect: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Two Seater",
            size: 2,
            thumbnail: "/assets/system/2 SS.png",
            productId: "614716775f027f6a6fd20668", //wrong id //612fc0ee5c51e05fa805f7f1
            disableOptions: ["Intermediate-Leg Wire Entry"],
            enableOptions: ["Vertical Wire Entry"],
            tableTop: ["table_top_shared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
          },
          {
            name: "Four Seater",
            size: 4,
            thumbnail: "/assets/system/4 SS.png",
            productId: "6143198f3c1d3427538355fd", //612f97d18df4cf54a2247d7d
            disableOptions: ["Vertical Wire Entry"],
            enableOptions: ["Intermediate-Leg Wire Entry"],
            tableTop: ["table_top_shared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
          },
          {
            name: "Six Seater",
            size: 6,
            thumbnail: "/assets/system/6 SS.png",
            productId: "6146c04a1860351e3c5adc80", //612fc3265c51e05fa805f806
            enableOptions: [
              "Intermediate-Leg Wire Entry",
              "Vertical Wire Entry",
            ],

            tableTop: ["table_top_shared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
          },
          {
            name: "Eight Seater",
            size: 8,
            thumbnail: "/assets/system/8 SS.png",
            productId: "6146b25c894c3965983b8ed4", //612fc4b55c51e05fa805f81e
            enableOptions: [
              "Intermediate-Leg Wire Entry",
              "Vertical Wire Entry",
            ],
            tableTop: ["table_top_shared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
          },
          {
            name: "Ten Seater",
            size: 10,
            thumbnail: "/assets/system/10 SS.png",
            productId: "614471aac75f45820b5ba491", //612fc5f613b55e382747a237
            enableOptions: [
              "Intermediate-Leg Wire Entry",
              "Vertical Wire Entry",
            ],
            tableTop: ["table_top_shared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
          },
          {
            name: "Twelve Seater",
            size: 12,
            thumbnail: "/assets/system/12 SS.png",
            productId: "629f003238caeb176201b935", //612fc5f613b55e382747a237
            enableOptions: [
              "Intermediate-Leg Wire Entry",
              "Vertical Wire Entry",
            ],
            tableTop: ["table_top_shared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "One Seater",
            size: 1,
            thumbnail: "/assets/system/1NS.jpg",
            productId: "6147618d28019dec7fc9ad79", //612fccfb5c51e05fa805f84e
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: ["intermediate_wireentry"],
          },
          {
            name: "Two Seater",
            size: 2,
            thumbnail: "/assets/system/2 NS.jpg",
            productId: "6147607a28019dec7fc9ad6b", //612fcf025c51e05fa805f854
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Three Seater",
            size: 3,
            thumbnail: "/assets/system/3 NS.png",
            productId: "61475ec428019dec7fc9ad60",
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Four Seater",
            size: 4,
            thumbnail: "/assets/system/4 NS.png",
            productId: "61475b6d77334ae9c85c0200", //612fd2be34bcffa99edb8e93
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Five Seater",
            size: 5,
            thumbnail: "/assets/system/5 NS.png",
            productId: "614319dd3c1d34275383560b", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
          {
            name: "Six Seater",
            size: 6,
            thumbnail: "/assets/system/6 NS.png",
            productId: "62a6dc01e175821c6172fc5a", //612fd493daa6800c05b5ea77
            tableTop: ["table_top_nonshared"],
            leftLegs: ["left_leg"],
            rightLegs: ["right_leg"],
            intermediateLegs: [
              "intermediate_wireentry",
              "intermediate_wireentry",
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Dimensions",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "675 mm",
                value: 675,
              },
              {
                name: "700 mm",
                value: 700,
              },
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "800 mm",
                value: 800,
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        multiselect: false,
        options: [
          {
            name: "Length",
            required: false,
            dropDown: true,
            options: [
              {
                name: "900 mm",
                value: 900,
              },
              {
                name: "1050 mm",
                value: 1050,
              },
              {
                name: "1200 mm",
                value: 1200,
              },
              {
                name: "1350 mm",
                value: 1350,
              },
              {
                name: "1500 mm",
                value: 1500,
              },
              {
                name: "1650 mm",
                value: 1650,
              },
            ],
          },
          {
            name: "Depth",
            required: false,
            dropDown: true,
            options: [
              {
                name: "600 mm",
                value: 600,
              },
              {
                name: "675 mm",
                value: 675,
              },
              {
                name: "700 mm",
                value: 700,
              },
              {
                name: "750 mm",
                value: 750,
              },
              {
                name: "800 mm",
                value: 800,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Legs",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Colors",
                thumbnail: "/assets/components/ColorsSharing.png",
                enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "6135b40ef171b47a874cc0f5", //62ab2526ed5393b0a46cf009 - new one with fabric
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "61321a9fdccc63542ab0347c", //62ab239fed5393b0a46cef70 - new one with fabric
                  },

                  {
                    type: replacePart,
                    condition: [{ feature: "Depth", values: [600, 675] }],
                    node: [
                      "beam1_shared",
                      "beam2_shared",
                      "beam1_shared_1",
                      "beam2_shared_1",
                      "beam1_shared_2",
                      "beam2_shared_2",
                      "beam1_shared_3",
                      "beam2_shared_3",
                      "beam1_shared_4",
                      "beam2_shared_4",
                      "beam1_shared_5",
                      "beam2_shared_5",
                    ],
                    pid: "6135fe400134312d3de728cd", //old-beam - 6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam1_shared",

                      "beam1_shared_1",

                      "beam1_shared_2",

                      "beam1_shared_3",

                      "beam1_shared_4",

                      "beam1_shared_5",
                    ],
                    pid: "6166d4974cfdb2a494bd1ba1", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam2_shared",

                      "beam2_shared_1",

                      "beam2_shared_2",

                      "beam2_shared_3",

                      "beam2_shared_4",

                      "beam2_shared_5",
                    ],
                    pid: "6166d96776165045d5b9328b", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: "replacePart",
                    node: [
                      "indermediate_leg",
                      "intermediate_leg4s",
                      "intermediate_leg6s",
                      "intermediate_leg6A",
                      "intermediate_leg8A",
                      "intermediate_leg8s",
                      "intermediate_leg10s",
                      "intermediate_leg10A",
                      "intermediate_leg12s",
                    ],
                    pid: "612cd97818ef64345b011715", //612e57bf577fd4d9c02b1b2c
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Collaborate",
                thumbnail: "/assets/components/CollaborateSharing.png",
                disableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "61310c833f05e76a8bed3ef8", //new id - 61310c833f05e76a8bed3ef8
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "6130f93cf389b70eb817b9ea", //new id - 6130f93cf389b70eb817b9ea
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Depth", values: [600, 675] }],
                    node: [
                      "beam1_shared",
                      "beam2_shared",
                      "beam1_shared_1",
                      "beam2_shared_1",
                      "beam1_shared_2",
                      "beam2_shared_2",
                      "beam1_shared_3",
                      "beam2_shared_3",
                      "beam1_shared_4",
                      "beam2_shared_4",
                      "beam1_shared_5",
                      "beam2_shared_5",
                    ],
                    pid: "6135fe400134312d3de728cd", //old - 6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam1_shared",

                      "beam1_shared_1",

                      "beam1_shared_2",

                      "beam1_shared_3",

                      "beam1_shared_4",

                      "beam1_shared_5",
                    ],
                    pid: "6166d4974cfdb2a494bd1ba1", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam2_shared",

                      "beam2_shared_1",

                      "beam2_shared_2",

                      "beam2_shared_3",

                      "beam2_shared_4",

                      "beam2_shared_5",
                    ],
                    pid: "6166d96776165045d5b9328b", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: "replacePart",
                    node: [
                      "indermediate_leg",
                      "intermediate_leg4s",
                      "intermediate_leg6s",
                      "intermediate_leg6A",
                      "intermediate_leg8A",
                      "intermediate_leg8s",
                      "intermediate_leg10s",
                      "intermediate_leg10A",
                      "intermediate_leg12s",
                    ],
                    pid: "612cd97818ef64345b011715", //612e57bf577fd4d9c02b1b2c
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Connect",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/FloatSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "6131a4662ed6971064f82bfc",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "6135b6f4c44eb64ec93b4608",
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Depth", values: [600, 675] }],
                    node: [
                      "beam1_shared",
                      "beam2_shared",
                      "beam1_shared_1",
                      "beam2_shared_1",
                      "beam1_shared_2",
                      "beam2_shared_2",
                      "beam1_shared_3",
                      "beam2_shared_3",
                      "beam1_shared_4",
                      "beam2_shared_4",
                      "beam1_shared_5",
                      "beam2_shared_5",
                    ],
                    pid: "6135fe400134312d3de728cd", //old beam - 6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam1_shared",

                      "beam1_shared_1",

                      "beam1_shared_2",

                      "beam1_shared_3",

                      "beam1_shared_4",

                      "beam1_shared_5",
                    ],
                    pid: "6166d4974cfdb2a494bd1ba1", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam2_shared",

                      "beam2_shared_1",

                      "beam2_shared_2",

                      "beam2_shared_3",

                      "beam2_shared_4",

                      "beam2_shared_5",
                    ],
                    pid: "6166d96776165045d5b9328b", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: "replacePart",
                    node: [
                      "indermediate_leg",
                      "intermediate_leg4s",
                      "intermediate_leg6s",
                      "intermediate_leg6A",
                      "intermediate_leg8A",
                      "intermediate_leg8s",
                      "intermediate_leg10s",
                      "intermediate_leg10A",
                      "intermediate_leg12s",
                    ],
                    pid: "612cd97818ef64345b011715", //612e57bf577fd4d9c02b1b2c
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Perform",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/PerformSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "61309ca43da2338c4065bea9", //new id - 61309ca43da2338c4065bea9
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "6130d7f780297d1c5d741e47", //new id - 6130d7f780297d1c5d741e47
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Depth", values: [600, 675] }],
                    node: [
                      "beam1_shared",
                      "beam2_shared",
                      "beam1_shared_1",
                      "beam2_shared_1",
                      "beam1_shared_2",
                      "beam2_shared_2",
                      "beam1_shared_3",
                      "beam2_shared_3",
                      "beam1_shared_4",
                      "beam2_shared_4",
                      "beam1_shared_5",
                      "beam2_shared_5",
                    ],
                    pid: "6135fe400134312d3de728cd", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam1_shared",

                      "beam1_shared_1",

                      "beam1_shared_2",

                      "beam1_shared_3",

                      "beam1_shared_4",

                      "beam1_shared_5",
                    ],
                    pid: "6166d4974cfdb2a494bd1ba1", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam2_shared",

                      "beam2_shared_1",

                      "beam2_shared_2",

                      "beam2_shared_3",

                      "beam2_shared_4",

                      "beam2_shared_5",
                    ],
                    pid: "6166d96776165045d5b9328b", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: "replacePart",
                    node: [
                      "indermediate_leg",
                      "intermediate_leg4s",
                      "intermediate_leg6s",
                      "intermediate_leg6A",
                      "intermediate_leg8A",
                      "intermediate_leg8s",
                      "intermediate_leg10s",
                      "intermediate_leg10A",
                      "intermediate_leg12s",
                    ],
                    pid: "612cd97818ef64345b011715", //612e57bf577fd4d9c02b1b2c
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Switch 40x40",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/SwitchSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "61360188351b306c0063e1d2", //61276ce6c05692405c325328
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "6139f4ee925d78204b1132fa", //61276b94d74c7fe57c941d63
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Depth", values: [600, 675] }],
                    node: [
                      "beam1_shared",
                      "beam1_shared_1",
                      "beam1_shared_2",
                      "beam1_shared_3",
                      "beam1_shared_4",
                      "beam1_shared_5",
                    ],
                    pid: "633a97d3f7aa9a6aa70f21ca", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: hide,
                    node: [
                      "intermediate_leg",
                      "intermediate_leg4s",
                      "intermediate_leg6s",
                      "intermediate_leg8s",
                      "intermediate_leg10s",
                      "intermediate_leg12s",
                    ],
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Depth", values: [600, 675] }],
                    node: [
                      "beam2_shared",

                      "beam2_shared_1",

                      "beam2_shared_2",

                      "beam2_shared_3",

                      "beam2_shared_4",

                      "beam2_shared_5",
                    ],
                    pid: "633a9a6ff7aa9a6aa70f21db", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam1_shared",

                      "beam1_shared_1",

                      "beam1_shared_2",

                      "beam1_shared_3",

                      "beam1_shared_4",

                      "beam1_shared_5",
                    ],
                    pid: "633a90f579c4521ad31b0502", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam2_shared",

                      "beam2_shared_1",

                      "beam2_shared_2",

                      "beam2_shared_3",

                      "beam2_shared_4",

                      "beam2_shared_5",
                    ],
                    pid: "633a941179c4521ad31b0514", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: "replacePart",
                    node: [
                      "indermediate_leg",
                      "intermediate_leg4s",
                      "intermediate_leg6s",
                      "intermediate_leg6A",
                      "intermediate_leg8A",
                      "intermediate_leg8s",
                      "intermediate_leg10s",
                      "intermediate_leg10A",
                      "intermediate_leg12s",
                    ],
                    pid: "61371cf5d669b0177135c6c0", //612e57bf577fd4d9c02b1b2c
                  },

                  ...hideModestiesRules,
                ],
              },
              {
                name: "Switch Angular",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/SwitchAngularSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "6135bcfd3cf542d8c43e52fc",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "6135bf0f3941d41f0cb15694",
                  },
                  {
                    type: hide,
                    node: [
                      "beam1_shared",
                      "beam2_shared",
                      "beam1_shared_1",
                      "beam2_shared_1",
                      "beam1_shared_2",
                      "beam2_shared_2",
                      "beam1_shared_3",
                      "beam2_shared_3",
                      "beam1_shared_4",
                      "beam2_shared_4",
                      "beam1_shared_5",
                      "beam2_shared_5",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "intermediate_leg",
                      "intermediate_leg4s",
                      "intermediate_leg6s",
                      "intermediate_leg8s",
                      "intermediate_leg10s",
                      "intermediate_leg12s",
                    ],
                  },
                  {
                    type: "replacePart",
                    node: [
                      "beam1_shared",
                      "beam1_shared_1",
                      "beam1_shared_2",
                      "beam1_shared_3",
                      "beam1_shared_4",
                      "beam1_shared_5",
                    ],
                    pid: "6139c521c6b57cc0a2c4011c", //612e12a4a669079348767f11
                  },
                  {
                    type: "replacePart",
                    node: [
                      "indermediate_leg",
                      "intermediate_leg4s",
                      "intermediate_leg6s",
                      "intermediate_leg6A",
                      "intermediate_leg8A",
                      "intermediate_leg8s",
                      "intermediate_leg10s",
                      "intermediate_leg10A",
                      "intermediate_leg12s",
                    ],
                    pid: "61371cf5d669b0177135c6c0", //612e57bf577fd4d9c02b1b2c
                  },

                  ...hideModestiesRules,
                ],
              },
              {
                name: "Synergy",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/SynergySharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "613cb2cd96dfec8fda495a31", //611a127008dc8e016c4e8f22
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "613cb809e0a12a9afd8444c2", //6119e5c40a91040d6fb80efe
                  },
                  {
                    type: replacePart,
                    condition: [{ feature: "Depth", values: [600, 675] }],
                    node: [
                      "beam1_shared",
                      "beam2_shared",
                      "beam1_shared_1",
                      "beam2_shared_1",
                      "beam1_shared_2",
                      "beam2_shared_2",
                      "beam1_shared_3",
                      "beam2_shared_3",
                      "beam1_shared_4",
                      "beam2_shared_4",
                      "beam1_shared_5",
                      "beam2_shared_5",
                    ],
                    pid: "6135fe400134312d3de728cd", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam1_shared",

                      "beam1_shared_1",

                      "beam1_shared_2",

                      "beam1_shared_3",

                      "beam1_shared_4",

                      "beam1_shared_5",
                    ],
                    pid: "6166d4974cfdb2a494bd1ba1", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: replacePart,
                    condition: [
                      { feature: "Depth", values: [700, 750, 800, 850] },
                    ],
                    node: [
                      "beam2_shared",

                      "beam2_shared_1",

                      "beam2_shared_2",

                      "beam2_shared_3",

                      "beam2_shared_4",

                      "beam2_shared_5",
                    ],
                    pid: "6166d96776165045d5b9328b", //6119e5730a91040d6fb80ef9
                  },
                  {
                    type: "replacePart",
                    node: [
                      "indermediate_leg",
                      "intermediate_leg4s",
                      "intermediate_leg6s",
                      "intermediate_leg6A",
                      "intermediate_leg8A",
                      "intermediate_leg8s",
                      "intermediate_leg10s",
                      "intermediate_leg10A",
                      "intermediate_leg12s",
                    ],
                    pid: "612cd97818ef64345b011715", //612e57bf577fd4d9c02b1b2c
                  },
                  ...hideModestiesRules,
                ],
              },
            ],
          },
          {
            name: "Color Options",
            required: false,
            options: [
              {
                name: "Red",
                thumbnail: "/assets/textures/ColorsLeg/Red-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Red-fl.jpg",
                  },
                ],
              },
              {
                name: "Blue",
                thumbnail: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/ColorsLeg/Green-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Green-fl.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Yellow.jpg",
                  },
                ],
              },
              {
                name: "Ivory Nandini",
                thumbnail: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail: "/assets/textures/ColorsLeg/White-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/White-fl.jpg",
                  },
                ],
              },
              {
                name: "Mustard Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/ColorsLeg/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Orange.jpg",
                  },
                ],
              },
              {
                name: "Dark Grey",
                thumbnail: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/ColorsLeg/Black-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Black-fl.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Legs",
            required: false,
            options: [
              {
                name: "Colors",
                thumbnail: "/assets/components/ColorsNonSharing.png",
                enableFeatures: ["Color Options"],
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "6135b8f98b4859177c017dc0", //62a982973e93e5f5b5754859
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "6135b9cd6a0bb31ef279c84d", //62a98ffbaa9465d371f4708e
                  },

                  {
                    type: replacePart,
                    node: [
                      "beam_nonshared",
                      "beam_nonshared_1",
                      "beam_nonshared_2",
                      "beam_nonshared_3",
                      "beam_nonshared_4",
                      "beam_nonshared_5",
                    ],
                    pid: "613715ad3b10a82883828c6e", //6119e2f30a91040d6fb80ed4
                  },
                  {
                    type: "replacePart",
                    node: [
                      "intermediate_leg",
                      "intermediate_leg2ns",
                      "intermediate_leg3ns",
                      "intermediate_leg3Ans",
                      "intermediate_leg4Ans",
                      "intermediate_leg4ns",
                      "intermediate_leg5ns",
                      "intermediate_leg5Ans",
                      "intermediate_leg6ns",
                    ],
                    pid: "612d35f55b9d36d5804618de",
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Collaborate",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/CollaborateNonSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "61310eb510dcaca17d0940a9", //new id - 61310eb510dcaca17d0940a9
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "61310f9e95e5789981f66fd0", //new id - 61310f9e95e5789981f66fd0
                  },
                  {
                    type: replacePart,
                    node: [
                      "beam_nonshared",
                      "beam_nonshared_1",
                      "beam_nonshared_2",
                      "beam_nonshared_3",
                      "beam_nonshared_4",
                      "beam_nonshared_5",
                    ],
                    pid: "613715ad3b10a82883828c6e", //6119e2f30a91040d6fb80ed4
                  },
                  {
                    type: "replacePart",
                    node: [
                      "intermediate_leg",
                      "intermediate_leg2ns",
                      "intermediate_leg3ns",
                      "intermediate_leg3Ans",
                      "intermediate_leg4Ans",
                      "intermediate_leg4ns",
                      "intermediate_leg5ns",
                      "intermediate_leg5Ans",
                      "intermediate_leg6ns",
                    ],
                    pid: "612d35f55b9d36d5804618de",
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Connect",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/FloatNonSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "6135b770353814a63e6fd987",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "6135b87dc44eb64ec93b460c",
                  },
                  {
                    type: replacePart,
                    node: [
                      "beam_nonshared",
                      "beam_nonshared_1",
                      "beam_nonshared_2",
                      "beam_nonshared_3",
                      "beam_nonshared_4",
                      "beam_nonshared_5",
                    ],
                    pid: "613715ad3b10a82883828c6e", //6119e2f30a91040d6fb80ed4
                  },
                  {
                    type: "replacePart",
                    node: [
                      "intermediate_leg",
                      "intermediate_leg2ns",
                      "intermediate_leg3ns",
                      "intermediate_leg3Ans",
                      "intermediate_leg4Ans",
                      "intermediate_leg4ns",
                      "intermediate_leg5ns",
                      "intermediate_leg5Ans",
                      "intermediate_leg6ns",
                    ],
                    pid: "612d35f55b9d36d5804618de",
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Perform",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/PerformNonSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "6130f1cf47773ea6647a09da", //new id - 6130f1cf47773ea6647a09da
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "6130f43347773ea6647a09eb", //new id - 6130f43347773ea6647a09eb
                  },
                  {
                    type: replacePart,
                    node: [
                      "beam_nonshared",
                      "beam_nonshared_1",
                      "beam_nonshared_2",
                      "beam_nonshared_3",
                      "beam_nonshared_4",
                      "beam_nonshared_5",
                    ],
                    pid: "613715ad3b10a82883828c6e", //6119e2f30a91040d6fb80ed4
                  },
                  {
                    type: "replacePart",
                    node: [
                      "intermediate_leg",
                      "intermediate_leg2ns",
                      "intermediate_leg3ns",
                      "intermediate_leg3Ans",
                      "intermediate_leg4Ans",
                      "intermediate_leg4ns",
                      "intermediate_leg5ns",
                      "intermediate_leg5Ans",
                      "intermediate_leg6ns",
                    ],
                    pid: "612d35f55b9d36d5804618de",
                  },
                  ...hideModestiesRules,
                ],
              },
              {
                name: "Switch 40x40",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/SwitchNonSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "6135cea55694ab5d612e67dd",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "6135d27ff50f735a7b603f82",
                  },
                  {
                    type: hide,
                    node: [
                      "beam_nonshared",
                      "beam_nonshared_1",
                      "beam_nonshared_2",
                      "beam_nonshared_3",
                      "beam_nonshared_4",
                      "beam_nonshared_5",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "intermediate_leg",
                      "intermediate_leg2ns",
                      "intermediate_leg3ns",
                      "intermediate_leg4ns",
                      "intermediate_leg5ns",
                      "intermediate_leg6ns",
                    ],
                  },
                  {
                    type: "replacePart",
                    node: [
                      "beam_nonshared",
                      "beam_nonshared_1",
                      "beam_nonshared_2",
                      "beam_nonshared_3",
                      "beam_nonshared_4",
                      "beam_nonshared_5",
                    ], //"beam_nonshared"
                    pid: "613a09c9f63390965564bd1b", //61307a70754f09a5159adddc  //613a09c9f63390965564bd1b
                  },
                  {
                    type: "replacePart",
                    node: [
                      "intermediate_leg",
                      "intermediate_leg2ns",
                      "intermediate_leg3ns",
                      "intermediate_leg3Ans",
                      "intermediate_leg4Ans",
                      "intermediate_leg4ns",
                      "intermediate_leg5ns",
                      "intermediate_leg5Ans",
                      "intermediate_leg6ns",
                    ],
                    pid: "61371e9dd669b0177135c6c3",
                  },

                  ...hideModestiesRules,
                ],
              },
              {
                name: "Switch Angular",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/SwitchAngularNonSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "6135c0a940fc43de8da1a710",
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "6135c1423941d41f0cb15696",
                  },
                  {
                    type: hide,
                    node: [
                      "beam_nonshared",
                      "beam_nonshared_1",
                      "beam_nonshared_2",
                      "beam_nonshared_3",
                      "beam_nonshared_4",
                      "beam_nonshared_5",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "intermediate_leg",
                      "intermediate_leg2ns",
                      "intermediate_leg3ns",
                      "intermediate_leg4ns",
                      "intermediate_leg5ns",
                      "intermediate_leg6ns",
                    ],
                  },
                  {
                    type: "replacePart",
                    node: [
                      "beam_nonshared",
                      "beam_nonshared_1",
                      "beam_nonshared_2",
                      "beam_nonshared_3",
                      "beam_nonshared_4",
                      "beam_nonshared_5",
                    ],
                    pid: "613cd5a5b78dc0836b8043bb", //612e1b34ab8e1aa95b7f92a8
                  },
                  {
                    type: "replacePart",
                    node: [
                      "intermediate_leg",
                      "intermediate_leg2ns",
                      "intermediate_leg3ns",
                      "intermediate_leg3Ans",
                      "intermediate_leg4Ans",
                      "intermediate_leg4ns",
                      "intermediate_leg5ns",
                      "intermediate_leg5Ans",
                      "intermediate_leg6ns",
                    ],
                    pid: "61371e9dd669b0177135c6c3",
                  },

                  ...hideModestiesRules,
                ],
              },
              {
                name: "Synergy",
                disableFeatures: ["Color Options"],
                thumbnail: "/assets/components/SynergyNonSharing.png",
                rules: [
                  {
                    type: "replacePart",
                    node: "left_leg",
                    pid: "613cc0c5cdb651d98b0eee16", //6119e2cd1b1b60aa851e01f0
                  },
                  {
                    type: "replacePart",
                    node: "right_leg",
                    pid: "613cc351a457b8bf66334d66", //6119e3186a13f3c9f4d0a7bc
                  },
                  {
                    type: replacePart,
                    node: [
                      "beam_nonshared",
                      "beam_nonshared_1",
                      "beam_nonshared_2",
                      "beam_nonshared_3",
                      "beam_nonshared_4",
                      "beam_nonshared_5",
                    ],
                    pid: "613715ad3b10a82883828c6e", //6119e2f30a91040d6fb80ed4
                  },
                  {
                    type: "replacePart",
                    node: [
                      "intermediate_leg",
                      "intermediate_leg2ns",
                      "intermediate_leg3ns",
                      "intermediate_leg3Ans",
                      "intermediate_leg4Ans",
                      "intermediate_leg4ns",
                      "intermediate_leg5ns",
                      "intermediate_leg5Ans",
                      "intermediate_leg6ns",
                    ],
                    pid: "612d35f55b9d36d5804618de",
                  },
                  ...hideModestiesRules,
                ],
              },
            ],
          },
          {
            name: "Color Options",
            required: false,
            options: [
              {
                name: "Red",
                thumbnail: "/assets/textures/ColorsLeg/Red-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Red-fl.jpg",
                  },
                ],
              },
              {
                name: "Blue",
                thumbnail: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Blue-fl.jpg",
                  },
                ],
              },
              {
                name: "Green",
                thumbnail: "/assets/textures/ColorsLeg/Green-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Green-fl.jpg",
                  },
                ],
              },
              {
                name: "Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Yellow.jpg",
                  },
                ],
              },
              {
                name: "Ivory Nandini",
                thumbnail: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Ivory-nandini.jpg",
                  },
                ],
              },
              {
                name: "White",
                thumbnail: "/assets/textures/ColorsLeg/White-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/White-fl.jpg",
                  },
                ],
              },
              {
                name: "Mustard Yellow",
                thumbnail: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Mustard-yellow.jpg",
                  },
                ],
              },
              {
                name: "Orange",
                thumbnail: "/assets/textures/ColorsLeg/Orange.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Orange.jpg",
                  },
                ],
              },
              {
                name: "Dark Grey",
                thumbnail: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Dark-grey.jpg",
                  },
                ],
              },
              {
                name: "Silver Grey",
                thumbnail: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Silver-grey.jpg",
                  },
                ],
              },
              {
                name: "Black",
                thumbnail: "/assets/textures/ColorsLeg/Black-fl.jpg",
                rules: [
                  {
                    type: applyMaterial,
                    node: "glass_colors",
                    textureUrl: "/assets/textures/ColorsLeg/Black-fl.jpg",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Wire Management",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Raceway",
            required: false,

            rules: [
              {
                type: hide,
                node: "intermediate_leg",
              },
            ],
            options: [
              {
                name: "Box Raceway - 125mm",
                thumbnail: "/assets/components/racewaysmall.png",
                options: [...boxraceway],
                enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "flipdowntray1_shared",
                      "flipdowntray1_shared_1",
                      "flipdowntray1_shared_1A",
                      "flipdowntray1_shared_2",
                      "flipdowntray1_shared_2A",
                      "flipdowntray1_shared_3",
                      "flipdowntray1_shared_3A",
                      "flipdowntray1_shared_4",
                      "flipdowntray1_shared_4A",
                      "flipdowntray1_shared_5",
                    ],
                    pid: "612e4b11cbe653a8d05bce77",
                  },
                ],
              },
              {
                name: "Flip Down Tray",
                thumbnail: "/assets/components/flipdowntray.png",
                options: [...flipdowntray_shared],
                enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "flipdowntray1_shared",
                      "flipdowntray1_shared_1",
                      "flipdowntray1_shared_1A",
                      "flipdowntray1_shared_2",
                      "flipdowntray1_shared_2A",
                      "flipdowntray1_shared_3",
                      "flipdowntray1_shared_3A",
                      "flipdowntray1_shared_4",
                      "flipdowntray1_shared_4A",
                      "flipdowntray1_shared_5",
                    ],
                    pid: "6119e3b11b1b60aa851e0208",
                  },
                  // {
                  //   type: replacePart,
                  //   node: "intermediate_modesty_shared_1",
                  //   pid: "6119e3b11b1b60aa851e0208",
                  // },
                ],
              },
              {
                name: "Raceway laminate 250H",
                thumbnail: "/assets/components/250racewaysmalllaminated.png",
                options: [...raceway200sharedlaminate],
                enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "flipdowntray1_shared",
                      "flipdowntray1_shared_1",
                      "flipdowntray1_shared_1A",
                      "flipdowntray1_shared_2",
                      "flipdowntray1_shared_2A",
                      "flipdowntray1_shared_3",
                      "flipdowntray1_shared_3A",
                      "flipdowntray1_shared_4",
                      "flipdowntray1_shared_4A",
                      "flipdowntray1_shared_5",
                    ],
                    pid: "612f7e3605ba8ba613bfd966", //correct id - 612f7e3605ba8ba613bfd966
                  },
                  // {
                  //   type: replacePart,
                  //   node: "intermediate_modesty_shared_1",
                  //   pid: "612b2fc1abfcc0ebe5ea1c82",
                  // },
                ],
              },
              {
                name: "Raceway laminate 400H",
                thumbnail: "/assets/components/400racewaylaminated.png",
                options: [...raceway400sharedlaminate],
                disableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "flipdowntray1_shared",
                      "flipdowntray1_shared_1",
                      "flipdowntray1_shared_1A",
                      "flipdowntray1_shared_2",
                      "flipdowntray1_shared_2A",
                      "flipdowntray1_shared_3",
                      "flipdowntray1_shared_3A",
                      "flipdowntray1_shared_4",
                      "flipdowntray1_shared_4A",
                      "flipdowntray1_shared_5",
                    ],
                    pid: "627ccb3e9e39991ff023e681", //correct id - 61260186a914fd6d9767a2b8
                  },
                ],
              },
              {
                name: "Raceway metal 250H",
                thumbnail: "/assets/components/racewaysmall.png",
                options: [...raceway200shared],
                enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "flipdowntray1_shared",
                      "flipdowntray1_shared_1",
                      "flipdowntray1_shared_1A",
                      "flipdowntray1_shared_2A",
                      "flipdowntray1_shared_2",
                      "flipdowntray1_shared_3",
                      "flipdowntray1_shared_3A",
                      "flipdowntray1_shared_4",
                      "flipdowntray1_shared_4A",
                      "flipdowntray1_shared_5",
                    ],
                    pid: "627b465f1b8e29fd0c9e4e35", //612b2fc1abfcc0ebe5ea1c82
                  },
                  // {
                  //   type: replacePart,
                  //   node: "intermediate_modesty_shared_1",
                  //   pid: "612b2fc1abfcc0ebe5ea1c82",
                  // },
                ],
              },
              {
                name: "Raceway metal 400H",
                thumbnail: "/assets/components/racewaybig.png",
                options: [...raceway400shared],
                disableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "flipdowntray1_shared",
                      "flipdowntray1_shared_1",
                      "flipdowntray1_shared_1A",
                      "flipdowntray1_shared_2",
                      "flipdowntray1_shared_2A",
                      "flipdowntray1_shared_3",
                      "flipdowntray1_shared_3A",
                      "flipdowntray1_shared_4",
                      "flipdowntray1_shared_4A",
                      "flipdowntray1_shared_5",
                    ],
                    pid: "627cdaac973352dcf50a2454",
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Raceway",
            required: false,
            rules: [
              {
                type: hide,
                node: [
                  "cable_tray",
                  "intermediate_leg",
                ],
              },
            ],
            options: [
              {
                name: "Box Raceway - 125mm",
                thumbnail: "/assets/components/racewaysmall.png",
                options: [...boxracewaynonshared],
                enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "cable_tray",
                      "cable_tray_1",
                      "cable_tray_1A",
                      "cable_tray_2",
                      "cable_tray_2A",
                      "cable_tray_3A",
                      "cable_tray_3",
                      "cable_tray_4",
                      "cable_tray_4A",
                      "cable_tray_5",
                    ],
                    pid: "612f77c7113a99bc2c0167be",
                  },
                ],
              },
              {
                name: "Flip Down Tray",
                thumbnail: "/assets/components/flipdowntray.png",
                options: [...flipdowntray_nonshared],
                enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "cable_tray",
                      "cable_tray_1",
                      "cable_tray_1A",
                      "cable_tray_2",
                      "cable_tray_2A",
                      "cable_tray_3",
                      "cable_tray_3A",
                      "cable_tray_4",
                      "cable_tray_4A",
                      "cable_tray_5",
                    ],
                    pid: "611e5567740141dba667626f",
                  },
                ],
              },
              {
                name: "Raceway laminate 250H",
                thumbnail: "/assets/components/250racewaysmalllaminated.png",
                options: [...raceway200nonsharedlaminate],
                enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "cable_tray",
                      "cable_tray_1",
                      "cable_tray_1A",
                      "cable_tray_2",
                      "cable_tray_2A",
                      "cable_tray_3",
                      "cable_tray_3A",
                      "cable_tray_4",
                      "cable_tray_4A",
                      "cable_tray_5",
                    ],
                    pid: "612ca6c9b612b5a055efcc57", //correct id : 612ca6c9b612b5a055efcc57
                  },
                ],
              },
              {
                name: "Raceway laminate 400H",
                thumbnail: "/assets/components/400racewaylaminated.png",
                options: [...raceway400nonsharedlaminate],
                disableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "cable_tray",
                      "cable_tray_1",
                      "cable_tray_1A",
                      "cable_tray_2",
                      "cable_tray_2A",
                      "cable_tray_3",
                      "cable_tray_3A",
                      "cable_tray_4",
                      "cable_tray_4A",
                      "cable_tray_5",
                    ],
                    pid: "612391aabfce1f4aa3b6ce14", //correct id - 612391aabfce1f4aa3b6ce14
                  },
                ],
              },
              {
                name: "Raceway metal 250H",
                thumbnail: "/assets/components/racewaysmall.png",
                options: [...raceway200nonshared],
                enableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "cable_tray",
                      "cable_tray_1",
                      "cable_tray_1A",
                      "cable_tray_2",
                      "cable_tray_2A",
                      "cable_tray_3",
                      "cable_tray_3A",
                      "cable_tray_4",
                      "cable_tray_4A",
                      "cable_tray_5",
                    ],
                    pid: "627b88fdcbefb6c97f0c4d42",
                  },
                ],
              },
              {
                name: "Raceway metal 400H",
                thumbnail: "/assets/components/racewaybig.png",
                options: [...raceway400nonshared],
                disableFeatures: ["Main Modesty"],
                rules: [
                  {
                    type: replacePart,
                    node: [
                      "cable_tray",
                      "cable_tray_1",
                      "cable_tray_1A",
                      "cable_tray_2",
                      "cable_tray_2A",
                      "cable_tray_3A",
                      "cable_tray_3",
                      "cable_tray_4",
                      "cable_tray_4A",
                      "cable_tray_5",
                    ],
                    pid: "627b90495555c3137109f36c",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: "Powder Coat",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "Featherlite White Fine",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Metal/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Black.jpg",
              },
            ],
          },
          {
            name: "Featherlite White Fine",
            thumbnail:
              "/assets/textures/Metal/thumbnail/FeatherliteWhiteFineTexture.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl:
                  "/assets/textures/Metal/FeatherliteWhiteFineTexture.jpg",
              },
            ],
          },
          {
            name: "Ivory",
            thumbnail: "/assets/textures/Metal/thumbnail/Ivory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/Ivory.jpg",
              },
            ],
          },
          {
            name: "Metallic Dark Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MetallicDarkGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MetallicDarkGrey.jpg",
              },
            ],
          },
          {
            name: "Moonlight Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/MoonlightGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/MoonlightGrey.jpg",
              },
            ],
          },
          {
            name: "Silver Grey",
            thumbnail: "/assets/textures/Metal/thumbnail/SilverGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "legs",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
              {
                type: applyMaterial,
                node: "lambert",
                textureUrl: "/assets/textures/Metal/SilverGrey.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Laminate",
    multiSelect: false,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Black",
            thumbnail: "/assets/textures/Wood/thumbnail/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Black.jpg",
              },
            ],
          },
          {
            name: "Charcoal",
            thumbnail: "/assets/textures/Wood/thumbnail/Charcoal.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/Charcoal.jpg",
              },
            ],
          },
          {
            name: "Classic Planked Walnut",
            thumbnail:
              "/assets/textures/Wood/thumbnail/ClassicPlankedWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ClassicPlankedWalnut.jpg",
              },
            ],
          },
          {
            name: "Everest White",
            thumbnail: "/assets/textures/Wood/thumbnail/EverestWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/EverestWhite.jpg",
              },
            ],
          },
          {
            name: "Highland Pine",
            thumbnail: "/assets/textures/Wood/thumbnail/HighlandPine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/HighlandPine.jpg",
              },
            ],
          },
          {
            name: "Lyonus Walnut",
            thumbnail: "/assets/textures/Wood/thumbnail/LyonusWalnut.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/LyonusWalnut.jpg",
              },
            ],
          },
          {
            name: "Moldau Acacia",
            thumbnail: "/assets/textures/Wood/thumbnail/MoldauAcacia.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/MoldauAcacia.jpg",
              },
            ],
          },
          {
            name: "Sonama Oak",
            thumbnail: "/assets/textures/Wood/thumbnail/SonamaOak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/SonamaOakNew.jpg",
              },
            ],
          },
          {
            name: "Gothic Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/GothicGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/GothicGrey.jpg",
              },
            ],
          },
          {
            name: "Warm Grey",
            thumbnail: "/assets/textures/Wood/thumbnail/WarmGrey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/WarmGrey.jpg",
              },
            ],
          },
          {
            name: "Thansau Maple",
            thumbnail: "/assets/textures/Wood/thumbnail/ThansauMaple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/ThansauMaple.jpg",
              },
            ],
          },
          {
            name: "Urban Teak",
            thumbnail: "/assets/textures/Wood/thumbnail/UrbanTeak.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "table_top",
                textureUrl: "/assets/textures/Wood/2k/UrbanTeak.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Privacy",
    multiSelect: true,
    optional: false,
    options: [
      {
        name: "SHARING",
        required: false,

        options: [
          {
            name: "Main screen",
            required: false,
            rules: hideAccessories,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                  },
                ],
              },
              {
                name: "Glass-8MM-300H",
                thumbnail: "/assets/components/300Glass.png",
                disableOptions: ["Tray"],
                enableOptions: ["Nameboard", "Pen Holder", "White Board"],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: show,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                    pid: "612e468c6ac1667119930120",
                  },
                ],
              },
              {
                name: "Glass-8MM-350H",
                thumbnail: "/assets/components/350Glass.png",
                disableOptions: ["Tray"],
                enableOptions: ["Nameboard", "Pen Holder", "White Board"],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: show,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                    pid: "612ea161fb9cbd036918bd22",
                  },
                ],
              },
              {
                name: "Fabric-18MM Screen With Mag Strip-300H",
                thumbnail: "/assets/components/18mmFabric300.png",
                enableOptions: [
                  "Nameboard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                    pid: "612e5272cbe653a8d05bce9d",
                  },
                ],
              },
              {
                name: "Fabric-18MM Screen With Mag Strip-350H",
                thumbnail: "/assets/components/18mmFabric350h.png",
                enableOptions: [
                  "Nameboard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                    pid: "612e935d8c544046ef1b06fa",
                  },
                ],
              },
              {
                name: "30MM AL Screen Edge-Edge-300H",
                thumbnail: "/assets/components/30mmAlMain300.png",
                enableOptions: [
                  "Nameboard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                      ...returnScrees30mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                    pid: "619b8f6ec28fef6477ca979c", //612f2b7f27233cc344e3f7b8 61495f014f19e29f4e5cc069
                  },
                ],
              },
              {
                name: "30MM AL Screen Edge-Edge-350H",
                thumbnail: "/assets/components/30mmAlMain350h.png",
                enableOptions: [
                  "Nameboard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                      ...returnScrees30mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                    pid: "619cabee421931ec34aaf135", //612f2e6e27233cc344e3f7d4  614963814f19e29f4e5cc08c
                  },
                ],
              },
              {
                name: "30MM AL Screen-300H",
                thumbnail: "/assets/components/30mmAlMain300.png",
                enableOptions: [
                  "Nameboard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                      ...returnScrees30mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                    pid: "623af90fd91007c948a72975", //612f2b7f27233cc344e3f7b8 61495f014f19e29f4e5cc069
                  },
                ],
              },
              {
                name: "30MM AL Screen-350H",
                thumbnail: "/assets/components/30mmAlMain350h.png",
                enableOptions: [
                  "Nameboard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                      ...returnScrees30mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_shared",
                      "screen_shared_1",
                      "screen_shared_2",
                      "screen_shared_3",
                      "screen_shared_4",
                      "screen_shared_5",
                    ],
                    pid: "623afac7d91007c948a7297a", //612f2e6e27233cc344e3f7d4  614963814f19e29f4e5cc08c
                  },
                ],
              },
            ],
          },

          {
            name: "Side Modesty",
            nodeName: [
              "modesty_wood_left",
              "modesty_perforated_left",
              "modesty_wood_right",
              "modesty_perforated_right",
            ], //"modesty_left", "modesty_right",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: ["modesty_wood_left", "modesty_wood_right"],
                  },
                  {
                    type: "hide",
                    node: [
                      "modesty_perforated_left",
                      "modesty_perforated_right",
                    ],
                  },
                ],
              },
              {
                name: "Powder Coat",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                rules: [
                  {
                    type: "show",
                    node: [
                      "modesty_perforated_left",
                      "modesty_perforated_right",
                    ],
                  },
                  {
                    type: "hide",
                    node: ["modesty_wood_left", "modesty_wood_right"],
                  },
                  // {
                  //   type: "show",
                  //   node: "modesty",
                  // },
                  // {
                  //   type: "show",
                  //   node: "modesty_right",
                  // },
                ],
              },
              {
                name: "Laminate",
                //pid: "6122f35afe408b1279847325",
                thumbnail: "/assets/components/woodmodesty1.png",
                rules: [
                  {
                    type: "show",
                    node: ["modesty_wood_left", "modesty_wood_right"],
                  },
                  {
                    type: "hide",
                    node: [
                      "modesty_perforated_left",
                      "modesty_perforated_right",
                    ],
                  },
                  // {
                  //   type: "hide",
                  //   node: "modesty_perfofated1",
                  // },
                  // {
                  //   type: "show",
                  //   node: "modesty_right",
                  // },
                ],
              },
            ],
          },
          {
            name: "Main Modesty",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "intermediate_modesty_shared",
                      "intermediate_modesty_shared_1",
                      "intermediate_modesty_shared_2",
                      "intermediate_modesty_shared_3",
                      "intermediate_modesty_shared_4",
                      "intermediate_modesty_shared_5",
                    ],
                  },
                  // {
                  //   type: "hide",
                  //   node: "screen_shared_1",
                  // },
                ],
              },
              {
                name: "Powder Coat",
                thumbnail: "/assets/components/woodmodesty.png",
                rules: [
                  {
                    type: "show",
                    node: [
                      "intermediate_modesty_shared",
                      "intermediate_modesty_shared_1",
                      "intermediate_modesty_shared_2",
                      "intermediate_modesty_shared_3",
                      "intermediate_modesty_shared_4",
                      "intermediate_modesty_shared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Raceway",
                        values: ["Flip Down Tray"],
                      },
                    ],
                    node: [
                      "intermediate_modesty_shared",
                      "intermediate_modesty_shared_1",
                      "intermediate_modesty_shared_2",
                      "intermediate_modesty_shared_3",
                      "intermediate_modesty_shared_4",
                      "intermediate_modesty_shared_5",
                    ],
                    pid: "6122f35afe408b1279847325",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Raceway",
                        values: [
                          "Raceway laminate 250H",
                          "Raceway metal 250H",
                          "Box Raceway - 125mm",
                        ],
                      },
                    ],
                    node: [
                      "intermediate_modesty_shared",
                      "intermediate_modesty_shared_1",
                      "intermediate_modesty_shared_2",
                      "intermediate_modesty_shared_3",
                      "intermediate_modesty_shared_4",
                      "intermediate_modesty_shared_5",
                    ],
                    pid: "6167bd46e2a93be4d2154496",
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
              {
                name: "Laminate",
                thumbnail: "/assets/components/woodmodesty1.png",
                rules: [
                  {
                    type: "show",
                    node: [
                      "intermediate_modesty_shared",
                      "intermediate_modesty_shared_1",
                      "intermediate_modesty_shared_2",
                      "intermediate_modesty_shared_3",
                      "intermediate_modesty_shared_4",
                      "intermediate_modesty_shared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Raceway",
                        values: ["Flip Down Tray"],
                      },
                    ],
                    node: [
                      "intermediate_modesty_shared",
                      "intermediate_modesty_shared_1",
                      "intermediate_modesty_shared_2",
                      "intermediate_modesty_shared_3",
                      "intermediate_modesty_shared_4",
                      "intermediate_modesty_shared_5",
                    ],
                    pid: "612c8dc933ca49d19ea7cefc",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Raceway",
                        values: [
                          "Raceway laminate 250H",
                          "Raceway metal 250H",
                          "Box Raceway - 125mm",
                        ],
                      },
                    ],
                    node: [
                      "intermediate_modesty_shared",
                      "intermediate_modesty_shared_1",
                      "intermediate_modesty_shared_2",
                      "intermediate_modesty_shared_3",
                      "intermediate_modesty_shared_4",
                      "intermediate_modesty_shared_5",
                    ],
                    pid: "61680dc1bcf6cfbb8f859aef",
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Main screen",
            nodeName: "screen_nonshared",
            rules: hideAccessories,
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                  },
                  // {
                  //   type: "hide",
                  //   node: "screen_shared_1",
                  // },
                ],
              },
              {
                name: "Glass-8MM-300H",
                thumbnail: "/assets/components/300Glass.png",
                disableOptions: ["Tray"],
                enableOptions: ["Nameboard", "White Board", "Pen Holder"],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: show,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },

                  {
                    type: "show",
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                    pid: "612e468c6ac1667119930120",
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
              {
                name: "Glass-8MM-350H",
                thumbnail: "/assets/components/350Glass.png",
                disableOptions: ["Tray"],
                enableOptions: ["Nameboard", "White Board", "Pen Holder"],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: hide,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: show,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                    pid: "612ea161fb9cbd036918bd22",
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
              {
                name: "Fabric-18MM Screen With Mag Strip-300H",
                thumbnail: "/assets/components/18mmFabric300.png",
                enableOptions: [
                  "Nameboard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                    pid: "612e5272cbe653a8d05bce9d",
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
              {
                name: "Fabric-18MM Screen With Mag Strip-350H",
                thumbnail: "/assets/components/18mmFabric350h.png",
                enableOptions: [
                  "Nameboard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                    pid: "612e935d8c544046ef1b06fa",
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
              {
                name: "30MM AL Screen Edge-Edge-300H",
                thumbnail: "/assets/components/30mmAlMain300.png",
                enableOptions: [
                  "Namebard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                      ...returnScrees30mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                    pid: "619ca9a8460430ea0bcca725", //612f307a01ec4d8d5f6a5ea5  61496657d3b8348ebc44568c
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
              {
                name: "30MM AL Screen Edge-Edge-350H",
                thumbnail: "/assets/components/30mmAlMain350h.png",
                enableOptions: [
                  "Nameboard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                      ...returnScrees30mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                    pid: "619cab22421931ec34aaf130", //612f31f8ed1751076eab5ed1   61496785402b22c52a9134fe
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
              {
                name: "30MM AL Screen-300H",
                thumbnail: "/assets/components/30mmAlMain300.png",
                enableOptions: [
                  "Namebard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                      ...returnScrees30mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                    pid: "623afd1269a843cba72397f8", //612f307a01ec4d8d5f6a5ea5  61496657d3b8348ebc44568c
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
              {
                name: "30MM AL Screen-350H",
                thumbnail: "/assets/components/30mmAlMain350h.png",
                enableOptions: [
                  "Nameboard",
                  "Pen Holder",
                  "Tray",
                  "White Board",
                ],
                options: [
                  {
                    name: "Return screen",
                    required: false,
                    options: [
                      ...returnScreenNone,
                      ...returnScreenGlass,
                      ...returnScreen18mm,
                      ...returnScrees30mm,
                    ],
                  },
                ],
                rules: [
                  {
                    type: show,
                    node: [
                      "Fabric_Left",
                      "Fabric_Right",
                      "RightShoe_Fabric",
                      "LeftShoe_Fabric",
                    ],
                  },
                  {
                    type: hide,
                    node: [
                      "Glass_Left",
                      "Glass_Right",
                      "RightShoe_Glass",
                      "LeftShoe_Glass",
                    ],
                  },
                  {
                    type: "show",
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    node: [
                      "screen_nonshared",
                      "screen_nonshared_1",
                      "screen_nonshared_2",
                      "screen_nonshared_3",
                      "screen_nonshared_4",
                      "screen_nonshared_5",
                    ],
                    pid: "623afde897d5e67a5078c7c9", //612f31f8ed1751076eab5ed1   61496785402b22c52a9134fe
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
            ],
          },
          // {
          //   name: "Return screen",
          //   nodeName: "screen_nonshared",
          //   required: false,
          //   options: [
          //     {
          //       name: "None",
          //       thumbnail: "/assets/components/none.jpg",
          //       pid: "",
          //       rules: [
          //         {
          //           type: "hide",
          //           node: [
          //             "return_screen_",
          //             "return_screen_1",
          //             "return_screen_2",
          //             "return_screen_3",
          //             "return_screen_4",
          //           ],
          //         },
          //         {
          //           type: "hide",
          //           node: [
          //             "return_screen_001",
          //             "return_screen_002",
          //             "return_screen_003",
          //             "return_screen_004",
          //             "return_screen_005",
          //             "return_screen_",
          //           ],
          //         },
          //         // {
          //         //   type: "hide",
          //         //   node: "screen_shared_1",
          //         // },
          //       ],
          //     },
          //     {
          //       name: "18 MM Screen With Mag Strip 300 H",
          //       thumbnail: "/assets/components/18mmrs.png",
          //       rules: [
          //         {
          //           type: "show",
          //           node: [
          //             "return_screen_",
          //             "return_screen_1",
          //             "return_screen_2",
          //             "return_screen_3",
          //             "return_screen_4",
          //           ],
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: ["return_screen_001","return_screen_002","return_screen_003","return_screen_004","return_screen_005","return_screen_"],
          //         //   pid: "612f5ade96a01418a6d9916b",
          //         // },
          //         {
          //           type: replacePart,
          //           node: [
          //             "return_screen_001",
          //             "return_screen_002",
          //             "return_screen_003",
          //             "return_screen_004",
          //             "return_screen_005",
          //             "return_screen_",
          //           ],
          //           pid: "614d604a7962a24fa22d4904",
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: "screen_shared_1",
          //         //   pid: "6119e42d0a91040d6fb80ef3",
          //         // },
          //       ],
          //     },
          //     {
          //       name: "18 MM Screen With Mag Strip 350 H",
          //       thumbnail: "/assets/components/18mmrs.png",
          //       rules: [
          //         {
          //           type: "show",
          //           node: [
          //             "return_screen_",
          //             "return_screen_1",
          //             "return_screen_2",
          //             "return_screen_3",
          //             "return_screen_4",
          //           ],
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: ["return_screen_001","return_screen_002","return_screen_003","return_screen_004","return_screen_005","return_screen_"],
          //         //   pid: "612f5ade96a01418a6d9916b",
          //         // },
          //         {
          //           type: replacePart,
          //           node: [
          //             "return_screen_001",
          //             "return_screen_002",
          //             "return_screen_003",
          //             "return_screen_004",
          //             "return_screen_005",
          //             "return_screen_",
          //           ],
          //           pid: "614d4c4667cd1d38aa3ba4f3", //6135f24f945b46c29992012a
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: "screen_shared_1",
          //         //   pid: "6119e42d0a91040d6fb80ef3",
          //         // },
          //       ],
          //     },
          //     {
          //       name: "8MM Glass 300H",
          //       thumbnail: "/assets/components/glassrs.png",
          //       rules: [
          //         {
          //           type: "show",
          //           node: [
          //             "return_screen_",
          //             "return_screen_1",
          //             "return_screen_2",
          //             "return_screen_3",
          //             "return_screen_4",
          //           ],
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: ["return_screen_001","return_screen_003","return_screen_005","return_screen_007","return_screen_009","return_screen_011"],
          //         //   pid: "612f55dc96a01418a6d99144",
          //         // },
          //         {
          //           type: replacePart,
          //           node: [
          //             "return_screen_001",
          //             "return_screen_002",
          //             "return_screen_003",
          //             "return_screen_004",
          //             "return_screen_005",
          //             "return_screen_",
          //           ],
          //           pid: "612f9e174ffc5e9ec1cf499d",
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: "screen_shared_1",
          //         //   pid: "6119e42d0a91040d6fb80ef3",
          //         // },
          //       ],
          //     },
          //     {
          //       name: "8MM Glass 350H",
          //       thumbnail: "/assets/components/glassrs.png",
          //       rules: [
          //         {
          //           type: "show",
          //           node: [
          //             "return_screen_",
          //             "return_screen_1",
          //             "return_screen_2",
          //             "return_screen_3",
          //             "return_screen_4",
          //           ],
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: ["return_screen_001","return_screen_003","return_screen_005","return_screen_007","return_screen_009","return_screen_011"],
          //         //   pid: "612f55dc96a01418a6d99144",
          //         // },
          //         {
          //           type: replacePart,
          //           node: [
          //             "return_screen_001",
          //             "return_screen_002",
          //             "return_screen_003",
          //             "return_screen_004",
          //             "return_screen_005",
          //             "return_screen_",
          //           ],
          //           pid: "6135f7ac3219427d3b8da556",
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: "screen_shared_1",
          //         //   pid: "6119e42d0a91040d6fb80ef3",
          //         // },
          //       ],
          //     },
          //     {
          //       name: "30 MM AL Screen 300H ",
          //       thumbnail: "/assets/components/30mmrs.png",
          //       rules: [
          //         {
          //           type: "show",
          //           node: [
          //             "return_screen_",
          //             "return_screen_1",
          //             "return_screen_2",
          //             "return_screen_3",
          //             "return_screen_4",
          //           ],
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: ["return_screen_001","return_screen_003","return_screen_005","return_screen_007","return_screen_009","return_screen_011"],
          //         //   pid: "6135e0d5b2dba4c8b32aa0cb",
          //         // },
          //         {
          //           type: replacePart,
          //           node: [
          //             "return_screen_001",
          //             "return_screen_002",
          //             "return_screen_003",
          //             "return_screen_004",
          //             "return_screen_005",
          //             "return_screen_",
          //           ],
          //           pid: "61498d77d816c9caeb213a8d", //6135e963fb4295804fa4945f   6149760e5fb436b30a789625
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: "screen_shared_1",
          //         //   pid: "6119e42d0a91040d6fb80ef3",
          //         // },
          //       ],
          //     },
          //     {
          //       name: "30 MM AL Screen 350H",
          //       thumbnail: "/assets/components/30mmrs.png",
          //       rules: [
          //         {
          //           type: "show",
          //           node: [
          //             "return_screen_",
          //             "return_screen_1",
          //             "return_screen_2",
          //             "return_screen_3",
          //             "return_screen_4",
          //           ],
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: ["return_screen_001","return_screen_003","return_screen_005","return_screen_007","return_screen_009","return_screen_011"],
          //         //   pid: "6135eef0945b46c299920123",
          //         // },
          //         {
          //           type: replacePart,
          //           node: [
          //             "return_screen_001",
          //             "return_screen_002",
          //             "return_screen_003",
          //             "return_screen_004",
          //             "return_screen_005",
          //             "return_screen_",
          //           ],
          //           pid: "61498ac4a0deb0eeeeb59a3d", //  6135f042945b46c299920125 613c6bb32dd789f6d091a818
          //         },
          //         // {
          //         //   type: replacePart,
          //         //   node: "screen_shared_1",
          //         //   pid: "6119e42d0a91040d6fb80ef3",
          //         // },
          //       ],
          //     },
          //     // {
          //     //   name: "18 MM Screen With Mag Strip 350 H",
          //     //   thumbnail: "/assets/components/18mmscreen.png",
          //     //   rules: [
          //     //     {
          //     //       type: "show",
          //     //       node: ["screen_nonshared","screen_nonshared_1","screen_nonshared_2","screen_nonshared_3","screen_nonshared_4"],
          //     //     },
          //     //     {
          //     //       type: replacePart,
          //     //       node: ["screen_nonshared","screen_nonshared_1","screen_nonshared_2","screen_nonshared_3","screen_nonshared_4"],
          //     //       pid: "612e935d8c544046ef1b06fa",
          //     //     },
          //     //     // {
          //     //     //   type: replacePart,
          //     //     //   node: "screen_shared_1",
          //     //     //   pid: "6119e42d0a91040d6fb80ef3",
          //     //     // },
          //     //   ],
          //     // },
          //     // {
          //     //   name: "30 MM AL Screen 300H",
          //     //   thumbnail: "/assets/components/landing-page-image-6.df202f62.jpg",
          //     //   rules: [
          //     //     {
          //     //       type: "show",
          //     //       node: ["screen_nonshared","screen_nonshared_1","screen_nonshared_2","screen_nonshared_3","screen_nonshared_4"],
          //     //     },
          //     //     {
          //     //       type: replacePart,
          //     //       node: ["screen_nonshared","screen_nonshared_1","screen_nonshared_2","screen_nonshared_3","screen_nonshared_4"],
          //     //       pid: "612f307a01ec4d8d5f6a5ea5",
          //     //     },
          //     // {
          //     //   type: replacePart,
          //     //   node: "screen_shared_1",
          //     //   pid: "6119e42d0a91040d6fb80ef3",
          //     //     // },
          //     //   ],
          //     // },
          //     // {
          //     //   name: "30 MM AL Screen 350H",
          //     //   thumbnail: "/assets/components/landing-page-image-6.df202f62.jpg",
          //     //   rules: [
          //     //     {
          //     //       type: "show",
          //     //       node: ["screen_nonshared","screen_nonshared_1","screen_nonshared_2","screen_nonshared_3","screen_nonshared_4"],
          //     //     },
          //     //     {
          //     //       type: replacePart,
          //     //       node: ["screen_nonshared","screen_nonshared_1","screen_nonshared_2","screen_nonshared_3","screen_nonshared_4"],
          //     //       pid: "612f31f8ed1751076eab5ed1",
          //     //     },
          //     //     // {
          //     //     //   type: replacePart,
          //     //     //   node: "screen_shared_1",
          //     //     //   pid: "6119e42d0a91040d6fb80ef3",
          //     //     // },
          //     //   ],
          //     // },
          //     // {
          //     //   name: "8 MM Glass 300 H",
          //     //   thumbnail: "/assets/components/8mmscreen.png",
          //     //   rules: [
          //     //     {
          //     //       type: "show",
          //     //       node: ["screen_nonshared","screen_nonshared_1","screen_nonshared_2","screen_nonshared_3","screen_nonshared_4"],
          //     //     },
          //     //     {
          //     //       type: replacePart,
          //     //       node: ["screen_nonshared","screen_nonshared_1","screen_nonshared_2","screen_nonshared_3","screen_nonshared_4"],
          //     //       pid: "612e468c6ac1667119930120",
          //     //     },
          //     //     // {
          //     //     //   type: replacePart,
          //     //     //   node: "screen_shared_1",
          //     //     //   pid: "6119e42d0a91040d6fb80ef3",
          //     //     // },
          //     //   ],
          //     // },
          //     // {
          //     //   name: "8 MM Glass 350 H",
          //     //   thumbnail: "/assets/components/8mmscreen.png",
          //     //   rules: [
          //     //     {
          //     //       type: "show",
          //     //       node: ["screen_nonshared","screen_nonshared_1","screen_nonshared_2","screen_nonshared_3","screen_nonshared_4"],
          //     //     },
          //     //     {
          //     //       type: replacePart,
          //     //       node: ["screen_nonshared","screen_nonshared_1","screen_nonshared_2","screen_nonshared_3","screen_nonshared_4"],
          //     //       pid: "612ea161fb9cbd036918bd22",
          //     //     },
          //     //     // {
          //     //     //   type: replacePart,
          //     //     //   node: "screen_shared_1",
          //     //     //   pid: "6119e42d0a91040d6fb80ef3",
          //     //     // },
          //     //   ],
          //     // },
          //   ],
          // },
          {
            name: "Side Modesty",
            nodeName: [
              "modesty_wood_left",
              "modesty_perforated_left",
              "modesty_wood_right",
              "modesty_perforated_right",
            ], //"modesty_left", "modesty_right",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: ["modesty_wood_left", "modesty_wood_right"],
                  },
                  {
                    type: "hide",
                    node: [
                      "modesty_perforated_left",
                      "modesty_perforated_right",
                    ],
                  },
                ],
              },
              {
                name: "Powder Coat",
                thumbnail: "/assets/components/metalmodesty.png",
                //pid: "6122f35afe408b1279847325",
                rules: [
                  {
                    type: "show",
                    node: [
                      "modesty_perforated_left",
                      "modesty_perforated_right",
                    ],
                  },
                  {
                    type: "hide",
                    node: ["modesty_wood_left", "modesty_wood_right"],
                  },
                  // {
                  //   type: "show",
                  //   node: "modesty",
                  // },
                  // {
                  //   type: "show",
                  //   node: "modesty_right",
                  // },
                ],
              },
              {
                name: "Laminate",
                //pid: "6122f35afe408b1279847325",
                thumbnail: "/assets/components/woodmodesty1.png",
                rules: [
                  {
                    type: "show",
                    node: ["modesty_wood_left", "modesty_wood_right"],
                  },
                  {
                    type: "hide",
                    node: [
                      "modesty_perforated_left",
                      "modesty_perforated_right",
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: "Main Modesty",
            required: false,
            options: [
              {
                name: "None",
                thumbnail: "/assets/components/none.jpg",
                pid: "",
                rules: [
                  {
                    type: "hide",
                    node: [
                      "intermediate_modesty_nonshared",
                      "intermediate_modesty_nonshared_1",
                      "intermediate_modesty_nonshared_2",
                      "intermediate_modesty_nonshared_3",
                      "intermediate_modesty_nonshared_4",
                      "intermediate_modesty_nonshared_5",
                    ],
                  },
                ],
              },
              {
                name: "Powder Coat",
                thumbnail: "/assets/components/woodmodesty.png",
                pid: "",
                rules: [
                  {
                    type: "show",
                    node: [
                      "intermediate_modesty_nonshared",
                      "intermediate_modesty_nonshared_1",
                      "intermediate_modesty_nonshared_2",
                      "intermediate_modesty_nonshared_3",
                      "intermediate_modesty_nonshared_4",
                      "intermediate_modesty_nonshared_5",
                    ],
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Raceway",
                        values: ["Flip Down Tray"],
                      },
                    ],
                    node: [
                      "intermediate_modesty_nonshared",
                      "intermediate_modesty_nonshared_1",
                      "intermediate_modesty_nonshared_2",
                      "intermediate_modesty_nonshared_3",
                      "intermediate_modesty_nonshared_4",
                      "intermediate_modesty_nonshared_5",
                    ],
                    pid: "627b7e9ee781037281831d8c",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Raceway",
                        values: [
                          "Raceway laminate 250H",
                          "Raceway metal 250H",
                          "Box Raceway - 125mm",
                        ],
                      },
                    ],
                    node: [
                      "intermediate_modesty_nonshared",
                      "intermediate_modesty_nonshared_1",
                      "intermediate_modesty_nonshared_2",
                      "intermediate_modesty_nonshared_3",
                      "intermediate_modesty_nonshared_4",
                      "intermediate_modesty_nonshared_5",
                    ],
                    pid: "627b7e9ee781037281831d8c",
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
              {
                name: "Laminate",
                thumbnail: "/assets/components/woodmodesty1.png",
                pid: "",
                rules: [
                  {
                    type: "show",
                    node: "intermediate_modesty_nonshared",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Raceway",
                        values: ["Flip Down Tray"],
                      },
                    ],
                    node: [
                      "intermediate_modesty_nonshared",
                      "intermediate_modesty_nonshared_1",
                      "intermediate_modesty_nonshared_2",
                      "intermediate_modesty_nonshared_3",
                      "intermediate_modesty_nonshared_4",
                      "intermediate_modesty_nonshared_5",
                    ],
                    pid: "627b8116e781037281831d94",
                  },
                  {
                    type: replacePart,
                    condition: [
                      {
                        feature: "Raceway",
                        values: [
                          "Raceway laminate 250H",
                          "Raceway metal 250H",
                          "Box Raceway - 125mm",
                        ],
                      },
                    ],
                    node: [
                      "intermediate_modesty_nonshared",
                      "intermediate_modesty_nonshared_1",
                      "intermediate_modesty_nonshared_2",
                      "intermediate_modesty_nonshared_3",
                      "intermediate_modesty_nonshared_4",
                      "intermediate_modesty_nonshared_5",
                    ],
                    pid: "627b8116e781037281831d94",
                  },
                  // {
                  //   type: replacePart,
                  //   node: "screen_shared_1",
                  //   pid: "6119e42d0a91040d6fb80ef3",
                  // },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Glass Color",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Apple Green",
            thumbnail: "/assets/textures/Glass/Apple Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Apple Green.jpg",
              },
            ],
          },
          {
            name: "Aqua Maring",
            thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
              },
            ],
          },
          {
            name: "Azure Blue",
            thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
              },
            ],
          },
          {
            name: "Beige",
            thumbnail: "/assets/textures/Glass/Beige.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Beige.jpg",
              },
            ],
          },
          {
            name: "Black",
            thumbnail: "/assets/textures/Glass/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Black.jpg",
              },
            ],
          },
          {
            name: "Canary Yellow",
            thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
              },
            ],
          },
          {
            name: "Casis Purple",
            thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
              },
            ],
          },
          {
            name: "Classic Ivory",
            thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
              },
            ],
          },
          {
            name: "Crystal White",
            thumbnail: "/assets/textures/Glass/Crystal White.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Crystal White.jpg",
              },
            ],
          },
          {
            name: "Dark Blue",
            thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
              },
            ],
          },
          {
            name: "Dark Bronze",
            thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
              },
            ],
          },
          {
            name: "Dark Lavender",
            thumbnail: "/assets/textures/Glass/dark lavender.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/dark lavender.jpg",
              },
            ],
          },
          {
            name: "Dark Red",
            thumbnail: "/assets/textures/Glass/Dark Red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Dark Red.jpg",
              },
            ],
          },
          {
            name: "Ergo Grey",
            thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze Frosted",
            thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze",
            thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
              },
            ],
          },
          {
            name: "Euro Grey",
            thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
              },
            ],
          },
          {
            name: "Flame Red",
            thumbnail: "/assets/textures/Glass/Flame-red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Flame-red.jpg",
              },
            ],
          },
          {
            name: "Frosted White",
            thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
              },
            ],
          },
          {
            name: "Green Lime",
            thumbnail: "/assets/textures/Glass/Green Lime.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Green Lime.jpg",
              },
            ],
          },
          {
            name: "Mediterranean Blue",
            thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
              },
            ],
          },
          {
            name: "Ocean Green",
            thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
              },
            ],
          },
          {
            name: "Olive Green",
            thumbnail: "/assets/textures/Glass/Olive Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Olive Green.jpg",
              },
            ],
          },
          {
            name: "Orange Mist",
            thumbnail: "/assets/textures/Glass/orange mist.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/orange mist.jpg",
              },
            ],
          },
          {
            name: "Sky Blue",
            thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
              },
            ],
          },
          {
            name: "Solar Yellow",
            thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
              },
            ],
          },
          {
            name: "Steel Grey",
            thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
              },
            ],
          },
          {
            name: "Sunny Orange",
            thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
              },
            ],
          },
          {
            name: "Super White",
            thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
              },
            ],
          },
          {
            name: "Tangerine Orange",
            thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Apple Green",
            thumbnail: "/assets/textures/Glass/Apple Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Apple Green.jpg",
              },
            ],
          },
          {
            name: "Aqua Maring",
            thumbnail: "/assets/textures/Glass/Aqua Marine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Aqua Marine.jpg",
              },
            ],
          },
          {
            name: "Azure Blue",
            thumbnail: "/assets/textures/Glass/Azure Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Azure Blue.jpg",
              },
            ],
          },
          {
            name: "Beige",
            thumbnail: "/assets/textures/Glass/Beige.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Beige.jpg",
              },
            ],
          },
          {
            name: "Black",
            thumbnail: "/assets/textures/Glass/Black.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Black.jpg",
              },
            ],
          },
          {
            name: "Canary Yellow",
            thumbnail: "/assets/textures/Glass/Canary Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Canary Yellow.jpg",
              },
            ],
          },
          {
            name: "Casis Purple",
            thumbnail: "/assets/textures/Glass/Casis Purple.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Casis Purple.jpg",
              },
            ],
          },
          {
            name: "Classic Ivory",
            thumbnail: "/assets/textures/Glass/ClassicIvory.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/ClassicIvory.jpg",
              },
            ],
          },
          {
            name: "Crystal White",
            thumbnail: "/assets/textures/Glass/Crystal White.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Crystal White.jpg",
              },
            ],
          },
          {
            name: "Dark Blue",
            thumbnail: "/assets/textures/Glass/Dark Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Dark Blue.jpg",
              },
            ],
          },
          {
            name: "Dark Bronze",
            thumbnail: "/assets/textures/Glass/Dark Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Dark Bronze.jpg",
              },
            ],
          },
          {
            name: "Dark Lavender",
            thumbnail: "/assets/textures/Glass/dark lavender.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/dark lavender.jpg",
              },
            ],
          },
          {
            name: "Dark Red",
            thumbnail: "/assets/textures/Glass/Dark Red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Dark Red.jpg",
              },
            ],
          },
          {
            name: "Ergo Grey",
            thumbnail: "/assets/textures/Glass/Ergo Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Ergo Grey.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze Frosted",
            thumbnail: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Euro Bronze Frosted.jpg",
              },
            ],
          },
          {
            name: "Euro Bronze",
            thumbnail: "/assets/textures/Glass/Euro Bronze.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Euro Bronze.jpg",
              },
            ],
          },
          {
            name: "Euro Grey",
            thumbnail: "/assets/textures/Glass/Euro Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Euro Grey.jpg",
              },
            ],
          },
          {
            name: "Flame Red",
            thumbnail: "/assets/textures/Glass/Flame-red.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Flame-red.jpg",
              },
            ],
          },
          {
            name: "Frosted White",
            thumbnail: "/assets/textures/Glass/FrostedWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/FrostedWhite.jpg",
              },
            ],
          },
          {
            name: "Green Lime",
            thumbnail: "/assets/textures/Glass/Green Lime.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Green Lime.jpg",
              },
            ],
          },
          {
            name: "Mediterranean Blue",
            thumbnail: "/assets/textures/Glass/Mediterranean Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Mediterranean Blue.jpg",
              },
            ],
          },
          {
            name: "Ocean Green",
            thumbnail: "/assets/textures/Glass/Ocean Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Ocean Green.jpg",
              },
            ],
          },
          {
            name: "Olive Green",
            thumbnail: "/assets/textures/Glass/Olive Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Olive Green.jpg",
              },
            ],
          },
          {
            name: "Orange Mist",
            thumbnail: "/assets/textures/Glass/orange mist.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/orange mist.jpg",
              },
            ],
          },
          {
            name: "Sky Blue",
            thumbnail: "/assets/textures/Glass/Sky Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Sky Blue.jpg",
              },
            ],
          },
          {
            name: "Solar Yellow",
            thumbnail: "/assets/textures/Glass/Solar Yellow.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Solar Yellow.jpg",
              },
            ],
          },
          {
            name: "Steel Grey",
            thumbnail: "/assets/textures/Glass/Steel Grey.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Steel Grey.jpg",
              },
            ],
          },
          {
            name: "Sunny Orange",
            thumbnail: "/assets/textures/Glass/Sunny Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Sunny Orange.jpg",
              },
            ],
          },
          {
            name: "Super White",
            thumbnail: "/assets/textures/Glass/SuperWhite.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/SuperWhite.jpg",
              },
            ],
          },
          {
            name: "Tangerine Orange",
            thumbnail: "/assets/textures/Glass/Tangerine Orange.JPG",
            rules: [
              {
                type: applyMaterial,
                node: "glass",
                textureUrl: "/assets/textures/Glass/Tangerine Orange.JPG",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Fabric Color",
    options: [
      {
        name: "SHARING",
        required: false,
        options: [
          {
            name: "Caramel",
            thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Caramel.jpg",
              },
            ],
          },
          {
            name: "Clouds",
            thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Clouds.jpg",
              },
            ],
          },
          {
            name: "Dago Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
              },
            ],
          },
          {
            name: "Dago Orange",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
              },
            ],
          },
          {
            name: "Flame",
            thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Flame.jpg",
              },
            ],
          },
          {
            name: "Granada",
            thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Granada.jpg",
              },
            ],
          },
          {
            name: "Greysto",
            thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Greysto.jpg",
              },
            ],
          },
          {
            name: "Lemon",
            thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Lemon.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
              },
            ],
          },
          {
            name: "Mink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Mink.jpg",
              },
            ],
          },
          {
            name: "Olive",
            thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Olive.jpg",
              },
            ],
          },
          {
            name: "P Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/P_Green.jpg",
              },
            ],
          },
          {
            name: "Peppermint",
            thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
              },
            ],
          },
          {
            name: "Pink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Pink.jpg",
              },
            ],
          },
          {
            name: "Planet",
            thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Planet.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Sangria.jpg",
              },
            ],
          },
          {
            name: "Stone",
            thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Stone.jpg",
              },
            ],
          },
          {
            name: "Tangerine",
            thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
              },
            ],
          },
          {
            name: "Waterfall",
            thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        options: [
          {
            name: "Caramel",
            thumbnail: "/assets/textures/Fabric/thumbnails/Caramel.jpg",
            rules: [
              {
                type: show,
                node: ["Fabric_Right", "Fabric_Left"],
              },
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Caramel.jpg",
              },
            ],
          },
          {
            name: "Clouds",
            thumbnail: "/assets/textures/Fabric/thumbnails/Clouds.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Clouds.jpg",
              },
            ],
          },
          {
            name: "Dago Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Green.jpg",
              },
            ],
          },
          {
            name: "Dago Orange",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dago_Orange.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dago_Orange.jpg",
              },
            ],
          },
          {
            name: "Dolphin",
            thumbnail: "/assets/textures/Fabric/thumbnails/Dolphin.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Dolphin.jpg",
              },
            ],
          },
          {
            name: "Flame",
            thumbnail: "/assets/textures/Fabric/thumbnails/Flame.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Flame.jpg",
              },
            ],
          },
          {
            name: "Granada",
            thumbnail: "/assets/textures/Fabric/thumbnails/Granada.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Granada.jpg",
              },
            ],
          },
          {
            name: "Greysto",
            thumbnail: "/assets/textures/Fabric/thumbnails/Greysto.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Greysto.jpg",
              },
            ],
          },
          {
            name: "Lemon",
            thumbnail: "/assets/textures/Fabric/thumbnails/Lemon.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Lemon.jpg",
              },
            ],
          },
          {
            name: "Med Blue",
            thumbnail: "/assets/textures/Fabric/thumbnails/Med_Blue.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Med_Blue.jpg",
              },
            ],
          },
          {
            name: "Mink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Mink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Mink.jpg",
              },
            ],
          },
          {
            name: "Olive",
            thumbnail: "/assets/textures/Fabric/thumbnails/Olive.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Olive.jpg",
              },
            ],
          },
          {
            name: "P Green",
            thumbnail: "/assets/textures/Fabric/thumbnails/P_Green.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/P_Green.jpg",
              },
            ],
          },
          {
            name: "Peppermint",
            thumbnail: "/assets/textures/Fabric/thumbnails/Peppermint.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Peppermint.jpg",
              },
            ],
          },
          {
            name: "Pink",
            thumbnail: "/assets/textures/Fabric/thumbnails/Pink.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Pink.jpg",
              },
            ],
          },
          {
            name: "Planet",
            thumbnail: "/assets/textures/Fabric/thumbnails/Planet.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Planet.jpg",
              },
            ],
          },
          {
            name: "Sangria",
            thumbnail: "/assets/textures/Fabric/thumbnails/Sangria.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Sangria.jpg",
              },
            ],
          },
          {
            name: "Stone",
            thumbnail: "/assets/textures/Fabric/thumbnails/Stone.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Stone.jpg",
              },
            ],
          },
          {
            name: "Tangerine",
            thumbnail: "/assets/textures/Fabric/thumbnails/Tangerine.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Tangerine.jpg",
              },
            ],
          },
          {
            name: "Waterfall",
            thumbnail: "/assets/textures/Fabric/thumbnails/Waterfall.jpg",
            rules: [
              {
                type: applyMaterial,
                node: "fabric",
                textureUrl: "/assets/textures/Fabric/Waterfall.jpg",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Pedestal",
    multiSelect: false,
    options: [
      {
        name: "SHARING",
        required: false,
        multiSelect: false,

        options: [
          {
            name: "None",
            thumbnail: "/assets/components/none.jpg",
            pid: "",
            rules: [
              {
                type: unbind,
                node: [],
              },
            ],
          },
          {
            name: "1 Drawer + 1 Filling",
            thumbnail: "/assets/components/pedestals/1Drawer-1Filling.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d69da2433098fd060ef90b",
              },
            ],
          },
          {
            name: "2 Drawers + 1 Filling",
            thumbnail: "/assets/components/pedestals/2Drawer-1Flling.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6a103507257cafa1a28c2",
              },
            ],
          },
          {
            name: "2 Equal Drawers with Single Lock",
            thumbnail:
              "/assets/components/pedestals/2Equal_Drawer_With_Single_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6a02654c3cc26f9da0bf3",
              },
            ],
          },
          {
            name: "2 Equal Drawers with Individual Locks",
            thumbnail:
              "/assets/components/pedestals/2Equal_Drawer_With_Individual_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d69ec1433098fd060ef99f",
              },
            ],
          },
          {
            name: "3 Equal Drawers with Single Lock",
            thumbnail:
              "/assets/components/pedestals/3Equal_Drawer_With_Single_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6af04db8f2f8b8a9e04af",
              },
            ],
          },
          {
            name: "3 Equal Drawers with Individual Locks",
            thumbnail:
              "/assets/components/pedestals/3Equal_Drawer_With_Individual_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6a6b5fbc16494bcffe915",
              },
            ],
          },
          {
            name: "4 Equal Drawers with Single Lock",
            thumbnail:
              "/assets/components/pedestals/4Equal_Drawer_With_Single_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6b3156d1762afb79a5c0c",
              },
            ],
          },
          {
            name: "4 Equal Drawers with Two Locks",
            thumbnail:
              "/assets/components/pedestals/4Equal_Drawer_With_Two_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6b1d7db8f2f8b8a9e0559",
              },
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        multiSelect: false,

        options: [
          {
            name: "None",
            thumbnail: "/assets/components/none.jpg",
            pid: "",
            rules: [
              {
                type: unbind,
                node: [],
              },
            ],
          },
          {
            name: "1 Drawer + 1 Filling",
            thumbnail: "/assets/components/pedestals/1Drawer-1Filling.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d69da2433098fd060ef90b",
              },
            ],
          },
          {
            name: "2 Drawers + 1 Filling",
            thumbnail: "/assets/components/pedestals/2Drawer-1Flling.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6a103507257cafa1a28c2",
              },
            ],
          },
          {
            name: "2 Equal Drawers with Single Lock",
            thumbnail:
              "/assets/components/pedestals/2Equal_Drawer_With_Single_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6a02654c3cc26f9da0bf3",
              },
            ],
          },
          {
            name: "2 Equal Drawers with Individual Locks",
            thumbnail:
              "/assets/components/pedestals/2Equal_Drawer_With_Individual_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d69ec1433098fd060ef99f",
              },
            ],
          },
          {
            name: "3 Equal Drawers with Single Lock",
            thumbnail:
              "/assets/components/pedestals/3Equal_Drawer_With_Single_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6af04db8f2f8b8a9e04af",
              },
            ],
          },
          {
            name: "3 Equal Drawers with Individual Locks",
            thumbnail:
              "/assets/components/pedestals/3Equal_Drawer_With_Individual_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6a6b5fbc16494bcffe915",
              },
            ],
          },
          {
            name: "4 Equal Drawers with Single Lock",
            thumbnail:
              "/assets/components/pedestals/4Equal_Drawer_With_Single_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6b3156d1762afb79a5c0c",
              },
            ],
          },
          {
            name: "4 Equal Drawers with Two Locks",
            thumbnail:
              "/assets/components/pedestals/4Equal_Drawer_With_Two_Lock.png",
            rules: [
              {
                type: unbind,
                node: [],
              },
              {
                type: bindPart,
                node: [],
                pid: "62d6b1d7db8f2f8b8a9e0559",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Accessories",
    multiSelect: true,
    options: [
      {
        name: "SHARING",
        required: false,
        multiSelect: true,
        rules: [
          {
            type: "hide",
            node: [
              "nameboard21",
              "nameboard41",
              "nameboard61",
              "nameboard81",
              "nameboard101",
              "nameboard121",
              "nameboard22",
              "nameboard42",
              "nameboard62",
              "nameboard82",
              "nameboard102",
              "nameboard122",
            ],
          },
          {
            type: "hide",
            node: [
              "penholder21",
              "penholder41",
              "penholder61",
              "penholder81",
              "penholder101",
              "penholder121",
              "penholder22",
              "penholder42",
              "penholder62",
              "penholder82",
              "penholder102",
              "penholder122",
            ],
          },
          {
            type: "hide",
            node: [
              "tray21",
              "tray41",
              "tray61",
              "tray81",
              "tray101",
              "tray121",
              "tray22",
              "tray42",
              "tray62",
              "tray82",
              "tray102",
              "tray122",
            ],
          },
          // {
          //   type: "hide",
          //   node: "screen_shared_1",
          // },
        ],
        options: [
          // {
          //   name: "None",
          //   thumbnail: "/assets/components/none.jpg",
          //   pid: "",

          // },
          {
            name: "Nameboard",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "replacePart",
                node: [
                  "nameboard21",
                  "nameboard41",
                  "nameboard61",
                  "nameboard81",
                  "nameboard101",
                  "nameboard121",
                ],
                pid: "613ee8f37d9defe1fd4bd0d1", //611a127008dc8e016c4e8f22
              },
              {
                type: "replacePart",
                node: [
                  "nameboard22",
                  "nameboard42",
                  "nameboard62",
                  "nameboard82",
                  "nameboard102",
                  "nameboard122",
                ],
                pid: "614449393b0e6f14ed3a1337", //611a127008dc8e016c4e8f22
              },
              ...hideModestiesRules,
            ],
          },
          {
            name: "Pen Holder",
            thumbnail: "/assets/components/penholder.png",
            rules: [
              {
                type: "replacePart",
                node: [
                  "penholder21",
                  "penholder41",
                  "penholder61",
                  "penholder81",
                  "penholder101",
                  "penholder121",
                ],
                pid: "6148518801ca6d9d8aad441d", //new id - 61309ca43da2338c4065bea9
              },
              {
                type: "replacePart",
                node: [
                  "penholder22",
                  "penholder42",
                  "penholder62",
                  "penholder82",
                  "penholder102",
                  "penholder122",
                ],
                pid: "61444be27a67ff9b60069f14", //new id - 61309ca43da2338c4065bea9
              },
              ...hideModestiesRules,
            ],
          },
          // {
          //   name: "Tray",
          //   thumbnail: "/assets/components/tray.png",
          //   rules: [
          //     {
          //       type: "replacePart",
          //       node: ["tray21", "tray41", "tray61", "tray81", "tray101"],
          //       pid: "61484d6efc56a09882c5f46c",
          //     },
          //     {
          //       type: "replacePart",
          //       node: ["tray22", "tray42", "tray62", "tray82", "tray102"],
          //       pid: "614445a397c71a69aadaebd2",
          //     },
          //     ...hideModestiesRules,
          //   ],
          // },
          {
            name: "White Board",
            thumbnail: "/assets/components/Whiteboard.png",
            rules: [
              {
                type: "replacePart",
                node: [
                  "tray21",
                  "tray41",
                  "tray61",
                  "tray81",
                  "tray101",
                  "tray121",
                ],
                pid: "61adb5c3dd8ebf52c5c909ed", //new id - 61309ca43da2338c4065bea9
              },
              {
                type: "replacePart",
                node: [
                  "tray22",
                  "tray42",
                  "tray62",
                  "tray82",
                  "tray102",
                  "tray122",
                ],
                pid: "61af2537990da6501c59332d", //new id - 61309ca43da2338c4065bea9
              },
              ...hideModestiesRules,
            ],
          },
        ],
      },
      {
        name: "NON SHARING",
        required: false,
        multiSelect: true,
        rules: [
          {
            type: "hide",
            node: [
              "nameboard11",
              "nameboard21",
              "nameboard31",
              "nameboard41",
              "nameboard51",
              "nameboard61",
            ],
          },
          {
            type: "hide",
            node: [
              "penholder11",
              "penholder21",
              "penholder31",
              "penholder41",
              "penholder51",
              "penholder61",
            ],
          },
          {
            type: "hide",
            node: ["tray11", "tray21", "tray31", "tray41", "tray51", "tray61"],
          },
        ],
        options: [
          {
            name: "Nameboard",
            thumbnail: "/assets/components/nameboard.png",
            rules: [
              {
                type: "replacePart",
                node: [
                  "nameboard11",
                  "nameboard21",
                  "nameboard31",
                  "nameboard41",
                  "nameboard51",
                  "nameboard61",
                ],
                pid: "61b878a68c4339a1c56f0ddc", //6119e2cd1b1b60aa851e01f0  613ee8f37d9defe1fd4bd0d1
              },
              ...hideModestiesRules,
            ],
          },
          {
            name: "Pen Holder",
            thumbnail: "/assets/components/penholder.png",
            rules: [
              {
                type: "replacePart",
                node: [
                  "penholder11",
                  "penholder21",
                  "penholder31",
                  "penholder41",
                  "penholder51",
                  "penholder61",
                ],
                pid: "6148518801ca6d9d8aad441d",
              },
              ...hideModestiesRules,
            ],
          },
          {
            name: "White Board",
            thumbnail: "/assets/components/Whiteboard.png",
            rules: [
              {
                type: "replacePart",
                node: [
                  "tray11",
                  "tray21",
                  "tray31",
                  "tray41",
                  "tray51",
                  "tray61",
                ],
                pid: "623adb7de18be1936fe5c155",
              },
              ...hideModestiesRules,
            ],
          },
        ],
      },
    ],
  },
]);

export type ConfigData = typeof ConfData3;

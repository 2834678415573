//64b114c40df4500fda2f73c3

import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const ShippingDoc = () => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const id = params.get("id");
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {id && (
        <iframe
          src={`https://shipdocgenerator.vercel.app?id=${id}`}
          width={"100%"}
          height={"100%"}
        />
      )}
      {!id && <p>Please provide an order id</p>}
    </div>
  );
};

export default ShippingDoc;

import React from "react";
import { Product } from "../types/types";

const BomTable = ({ bom }: { bom: Product["bom"] }) => {

  const remainingAmount = 7754;
  const amountPerComponent = remainingAmount / bom.length;

  // Shuffle the prices within the copied bom array
  const prices = bom.map((item) => item.price + amountPerComponent);
  for (let i = prices.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [prices[i], prices[j]] = [prices[j], prices[i]];
  }
  return (
    <div>
      <h1 className="mb-2">BOM:</h1>
      <table>
        <thead>
          <tr className="text-left border">
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Quantity</th>
            <th className="px-4 py-2">Price</th>
          </tr>
        </thead>
        <tbody className="border">
          {bom.map((item, index) =>
            item.quantity !== 0 ? (
              <tr key={item.name} className="border">
                <td className="px-4 py-1">{item.name}</td>
                <td className="px-4 py-1">{item.quantity}</td>
                <td className="px-4 py-1">{prices[index]}</td>
              </tr>
            ) : null
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BomTable;

import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import {
  configDataConstants,
  configurationConstants,
} from "../store/actions/types";
import { Feature, Option, Rule, UserConfiguration } from "../types/types";

const LegsFeatureName = "Legs";
const ElectricalFeatureName = "Wire Management";
const PrivacyFeatureName = "Privacy";
const AccessoriesFeatureName = "Accessories";
const MetalFeatureName = "Powder Coat";
const GlassesFeatureName = "Glass Color";
const FabricFeatureName = "Fabric Color";
const WoodFeatureName = "Laminate";
const PedestalFeatureName = "Pedestal";
const SideTableFeatureName = "Side Table";
const LegColorFeatureName = "Color Options";
const useUserConfiguration = () => {
  const dispatch = useAppDispatch();

  const userConfiguration2: any = useAppSelector(
    (state) => state.configuration.userConfiguration2
  );

  const specs: Feature[] = useAppSelector((state) => state.configData.current);
  const specsOriginal: Feature[] = useAppSelector(
    (state) => state.configData.original
  );

  const enableDisableOptions = (option: Option) => {
    const { disableFeatures, enableFeatures, disableOptions, enableOptions } =
      option;
    if (disableFeatures) {
      dispatch({
        type: configurationConstants.DISABLE_FEATURES,
        payload: disableFeatures,
      });
    }

    if (enableFeatures) {
      dispatch({
        type: configurationConstants.ENABLE_FEATURES,
        payload: enableFeatures,
      });
    }

    if (disableOptions) {
      dispatch({
        type: configurationConstants.DISABLE_OPTIONS,
        payload: disableOptions,
      });
    }

    if (enableOptions) {
      dispatch({
        type: configurationConstants.ENABLE_OPTIONS,
        payload: enableOptions,
      });
    }
  };

  const removeDependentFeatures = (value: Feature & { option: Option }) => {
    (value.option?.options as Feature[] | undefined)?.forEach((subFeature) => {
      const option =
        userConfiguration2[subFeature.name as keyof UserConfiguration];
      if (option) {
        !(subFeature.options as Option[])
          .map((_option) => _option.name)
          .includes(option) &&
          dispatch({
            type: configurationConstants.DELETE_PROPERTY,
            payload: subFeature.name,
          });
      }
    });
  };

  const updateUserConfiguration = (
    featureName: string,
    value: Feature & { option: Option }
  ) => {
    enableDisableOptions(value.option);
    removeDependentFeatures(value);
    switch (featureName) {
      case "System":
        const newConfigData = specsOriginal.map((item: any) => {
          const filteredOptions = item.options
            .filter((option: any) => {
              if (item.name === "System") return true;
              return option.name === value.name;
            })
            .map((option: any) => ({ ...option }));
          if (item.name === "System")
            return { ...item, options: filteredOptions };
          return {
            ...item,
            options: filteredOptions[0]?.options,
            rules: filteredOptions[0]?.rules,
          };
        });
        dispatch({
          type: configDataConstants.SET_CURRENT_CONFIG_DATA,
          payload: newConfigData,
        });

        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: {
            type: value.name,
            noOfSeats: value.option.size,
            ...(value.option.id && { id: value.option.id }),
            ...(value.option.Length && { Length: value.option.Length }),
            ...(value.option.Depth && { Depth: value.option.Depth }),
            ...(value.option.Dia && { Dia: value.option.Dia }),
            ...(value.option.table && { table: value.option.table }),
          },
        });

        break;
      case "Dimensions":
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.value },
        });
        break;
      case AccessoriesFeatureName:
      case MetalFeatureName:
      case GlassesFeatureName:
      case FabricFeatureName:
      case WoodFeatureName:
      case LegColorFeatureName:
      case PedestalFeatureName:
      case SideTableFeatureName:
        // console.log(featureName, value);
        let _value = value.option.name;

        if (value.multiSelect) {
          _value = userConfiguration2[featureName]?.includes(value.option.name)
            ? userConfiguration2[featureName].filter(
                (_value: string) => _value !== value.option.name
              )
            : [...(userConfiguration2[featureName] || []), value.option.name];
        }
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [featureName]: _value },
        });

        break;
      case ElectricalFeatureName:
      case LegsFeatureName:
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });

        return;
      case PrivacyFeatureName:
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: { [value.name]: value.option.name },
        });
        dispatch({
          type: configurationConstants.UPDATE_CONFIGURATION2,
          payload: {
            [AccessoriesFeatureName]: [],
          },
        });
        return;

      default:
        break;
    }
  };

  const getSelectedOption = (
    featureName: string,
    subFeatureName: string
  ): Option[] => {
    const Feature: Feature | undefined = specs.find(
      (x: any) => x.name === featureName
    );
    // console.log(featureName, subFeatureName, Feature);
    if (!Feature) return [];
    // console.log(featureName, "featureName");
    // console.log(subFeatureName, "subfeature");
    switch (featureName) {
      case "System":
        if (subFeatureName !== userConfiguration2.type) {
          return [];
        }

        const system = Feature;
        const sharingOrNonSharing = (system.options as Feature[]).find(
          (x) => x.name === userConfiguration2.type
        );
        const selectedOption = (sharingOrNonSharing?.options as Option[]).find(
          (option) => option.size === userConfiguration2.noOfSeats
        );

        return selectedOption ? [selectedOption] : [];

      case "Dimensions":
        const subFeature: Option | undefined = (
          Feature.options as Option[]
        )?.find((x: any) => x.name === subFeatureName);
        const selectedOption2 = (
          subFeature?.options as Option[] | undefined
        )?.find(
          (option) =>
            option.value === userConfiguration2[subFeature?.name || ""]
        );

        return selectedOption2 ? [selectedOption2] : [];

      case AccessoriesFeatureName:
      case PedestalFeatureName:
      case SideTableFeatureName:
      case MetalFeatureName:
      case GlassesFeatureName:
      case FabricFeatureName:
      case WoodFeatureName:
        if (Feature.multiSelect) {
          const selectedOption3 = (Feature.options as Option[]).filter(
            (x: any) => userConfiguration2[Feature.name]?.includes(x.name)
          );
          return selectedOption3;
        } else {
          const selectedOption3 = (
            Feature.options as Option[] | undefined
          )?.find((option) => option.name === userConfiguration2[Feature.name]);
          return selectedOption3 ? [selectedOption3] : [];
        }

      case PrivacyFeatureName:
      case ElectricalFeatureName:
      case LegsFeatureName:
        let subFeature2: Option | undefined = (
          Feature.options as Option[]
        )?.find((x: any) => x.name === subFeatureName);
        if (!subFeature2) {
          Feature.options?.forEach((x) => {
            if (getSelectedOption(featureName, x.name) instanceof Array) {
              getSelectedOption(featureName, x.name)?.forEach((option) => {
                option?.options?.forEach((z) => {
                  if (z.name === subFeatureName) {
                    subFeature2 = z as Option;
                  }
                });
              });
            }
          });
        }
        const selectedOption4 = (
          subFeature2?.options as Option[] | undefined
        )?.find((option) => {
          return option.name === userConfiguration2[subFeature2?.name || ""];
        });
        return selectedOption4 ? [selectedOption4] : [];

      default:
        return [];
    }
  };

  const getRules = () => {
    const rules: { [key: string]: Rule[] } = {};
    Object.keys(userConfiguration2).forEach((key) => {
      switch (key) {
        case AccessoriesFeatureName:
        case MetalFeatureName:
        case GlassesFeatureName:
        case FabricFeatureName:
        case WoodFeatureName:
        case PedestalFeatureName:
        case SideTableFeatureName:
          const legs = specs.find((x: any) => x.name === key);
          const r1 = legs?.rules;
          const r2 = getSelectedOption(key, "")?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );

          rules[key] = [...(r1 || []), ...(r2 || [])];
          //append screen size to nodes

          if (key === AccessoriesFeatureName) {
            const mainScreen = getSelectedOption(
              PrivacyFeatureName,
              "Main screen"
            );
            if (mainScreen) {
              let screenSize =
                mainScreen &&
                mainScreen[0]?.name?.substr(mainScreen[0]?.name.length - 4, 4);
              screenSize = screenSize?.split("H")[0];

              rules[key] = rules[key].map((rule) => {
                return {
                  ...rule,
                  node: rule.node.map(
                    (nodeName) => nodeName + "_" + screenSize
                  ),
                };
              });
            }
          }

          break;
        case "Raceway":
          const electrical = specs.find(
            (x: any) => x.name === ElectricalFeatureName
          );
          const r3 = (electrical?.options as Option[] | undefined)?.find(
            (x: any) => x.name === "Raceway"
          )?.rules;
          const r4 = getSelectedOption(
            ElectricalFeatureName,
            "Raceway"
          )?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[ElectricalFeatureName] = [...(r3 || []), ...(r4 || [])];
          return;

        case "Wire Access":
        case "Wire Entry":
          const emt = getSelectedOption(ElectricalFeatureName, "Raceway");
          const emtOption =
            emt &&
            (emt[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r5 = emtOption?.rules as Rule[] | undefined;
          const r6 = getSelectedOption(ElectricalFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[ElectricalFeatureName] = [
            ...(rules[ElectricalFeatureName] || []),
            ...(r5 || []),
            ...(r6 || []),
          ];
          return;
        case "Legs":
          const emtLeg = getSelectedOption(LegsFeatureName, "Legs");
          const emtOptionLeg =
            emtLeg &&
            (emtLeg[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r7 = emtOptionLeg?.rules as Rule[] | undefined;
          const r8 = getSelectedOption(LegsFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[LegsFeatureName] = [
            ...(rules[LegsFeatureName] || []),
            ...(r7 || []),
            ...(r8 || []),
          ];
          return;
        case "Pedestal":
          const emtPed = getSelectedOption("Pedestal", "Pedestal");
          const emtOptionPed =
            emtPed &&
            (emtPed[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r77 = emtOptionPed?.rules as Rule[] | undefined;
          const r88 = getSelectedOption("Pedestal", key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[LegsFeatureName] = [
            ...(rules["Pedestal"] || []),
            ...(r77 || []),
            ...(r88 || []),
          ];
          return;

        case LegColorFeatureName:
          const emtLegColor = getSelectedOption(
            LegsFeatureName,
            LegColorFeatureName
          );
          const emtOptionLegColor =
            emtLegColor &&
            (emtLegColor[0]?.options as Feature[] | undefined)?.find(
              (x: any) => x.name === key
            );
          const r11 = emtOptionLegColor?.rules as Rule[] | undefined;
          const r12 = getSelectedOption(LegsFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[LegsFeatureName] = [
            ...(rules[LegsFeatureName] || []),
            ...(r11 || []),
            ...(r12 || []),
          ];
          return;
        case "Main screen":
        case "Return screen":
        case "Main Modesty":
        case "Side Modesty":
          const privacy = specs.find((x) => x.name === PrivacyFeatureName);
          const ms = (privacy?.options as Option[] | undefined)?.find(
            (x) => x.name === key
          );
          const r9 = ms?.rules as Rule[] | undefined;
          const r10 = getSelectedOption(PrivacyFeatureName, key)?.reduce(
            (rules: Rule[], option) => [...rules, ...(option?.rules || [])],
            []
          );
          rules[PrivacyFeatureName] = [
            ...(rules[PrivacyFeatureName] || []),
            ...(r9 || []),
            ...(r10 || []),
          ];
          return;

        default:
          break;
      }
    });
    const parseConditions = (rules: Rule[]) => {
      return rules.filter((rule) => {
        let filter = true;
        rule.condition?.forEach((condition) => {
          condition.values.includes(userConfiguration2[condition.feature])
            ? (filter = true)
            : (filter = false);
        });
        return filter;
      });
    };
    Object.keys(rules).forEach((key) => {
      rules[key] = parseConditions(rules[key]);
    });
    return rules;
  };

  return {
    updateUserConfiguration,
    userConfiguration2,
    getSelectedOption,
    getRules,
  };
};

export default useUserConfiguration;
